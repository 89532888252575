import { Suspense, ReactNode, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

export interface AppRouteObject extends RouteObject {
  meta?: {
    // 菜单名
    title?: string;

    // 菜单图标
    icon?: string;

    // 是否在菜单隐藏该路由
    hidden?: boolean;

    // 是否默认展示(常驻)
    permanent?: boolean;
  };

  children?: AppRouteObject[];
}

const loading = <div>loading...</div>;
export const getComponent = (path: string): ReactNode => {
  const Component = lazy(() => import(`@/pages${path}`));

  return (
    <Suspense fallback={loading}>
      <Component />
    </Suspense>
  );
};

const renderTitle = (title: string) => `${title}管理`;
const renderTitleList = (title: string) => `${title}列表`;

export const routes: AppRouteObject[] = [
  {
    path: '/home',
    meta: { title: '数据统计', icon: 'account', hidden: true },
    element: getComponent('/home'),
  },

  {
    path: '/user',
    meta: { title: '用户管理', icon: 'user' },
    element: getComponent('/user'),
  },
  {
    path: '/contest',
    meta: { title: '赛事管理', icon: 'user' },
    element: getComponent('/contest'),
  },
  {
    path: '/contest-creat',
    meta: { title: '赛事创建', icon: 'user', hidden: true },
    element: getComponent('/contest/contest-create'),
  },
  // {
  //   path: '/game',
  //   meta: { title: '游戏管理', icon: 'user' },
  //   element: getComponent('/game'),
  // },
  {
    path: '/sport-record',
    meta: { title: '运动记录管理', icon: 'user' },
    element: getComponent('/sport-record'),
  },
  {
    path: '/health-record',
    meta: { title: '健康数据管理', icon: 'user' },
    element: getComponent('/health-record'),
  },
  {
    path: '/login',
    element: getComponent('/login'),
    meta: { hidden: true },
  },
];

export default routes;
