import React, { memo, useState, useEffect, useRef } from 'react';
import { Table, message, ConfigProvider, Button, Space } from 'antd';
import FormRender, { useForm } from 'form-render';

// import { Form } from '@/index';
import ProTableHeader from './Pro-table-header';
import { requester } from '@/services/request';

import { tableDataType, reqType, AppTableProps } from './type';
// import 'antd/dist/antd.css';
import './index.less';
import { TABLE_BASE_URL } from '@/services/config';

const tableDataDefault: tableDataType = {
  list: [],
  page: 1,
  page_size: 10,
};

const reqDataDefault = {
  // 请求参数

  search: {},
  sort: {},
  page: {
    page: 1,
    page_size: 10,
    total: true,
  },
};

function onChange(pagination: any, filters: any, sorter: any, extra: any) {
  // console.log('params', pagination, filters, sorter, extra);
}

export default memo(function AppTable(props: AppTableProps): React.ReactElement {
  const {
    tabs,
    pageTitle,
    url,
    method = 'get',
    tableTools,
    preSubmit,
    requestData,
    request,
    otherTableProps,
    schema,
    formProps,
    ...other
  } = props;

  const form = useForm();

  const tabsReqInit: null | any = useRef(null);

  const [tableData, setTableData] = useState(tableDataDefault); // 表格数据
  const [selectRows, setSelectRows] = useState<any[]>([]); // 被选中的行数据对象数组
  const [selectRowKeys, setSelectRowKeys] = useState<any[]>([]); // 被选中行的keys
  const [reqData, setReqData] = useState({ ...reqDataDefault, ...requestData }); // 请求数据
  const [loading, setLoading] = useState(true); // loading

  /**
   * 请求数据
   */
  useEffect(() => {
    setLoading(true);
    initData();
  }, [reqData, props.reset]);

  useEffect(() => {
    if (tabs && Object.keys(tabs).length) {
      tabsReqInit.current = getTabsInitReq(tabs);
      if (Object.keys(tabs).length === 1) {
        if (Object.keys(tabsReqInit.current).length) {
          setReqData({
            ...reqData,
            search: { ...reqData.search, ...tabsReqInit.current },
          });
        }
      } else {
        // if (Object.keys(tabsReqInit.current).length === 2) {
        setReqData({
          ...reqData,
          search: { ...reqData.search, ...tabsReqInit.current },
        });
        // }
      }
    }
  }, [tabs]);

  /**
   * 初始化请求
   * @param data 请求参数，默认为
   */
  const initData = async (data = reqData) => {
    !loading && setLoading(true);
    let result = data;
    if (typeof preSubmit === 'function') {
      result = await preSubmit(data);
      // 防止preSubmit没有返回数据
      result = result || data;
    }
    Object.keys(result).forEach((key) => {
      if (typeof result[key] === 'object' && !Object.keys(result[key]).length) result[key] = undefined;
    });

    try {
      const res = await requester(TABLE_BASE_URL + url, {
        method,
        query: { pageNum: result.page.page, pageSize: result.page.page_size },
      });

      setTableData({
        ...tableData,
        ...{
          list: res?.records ?? [],
          page: res?.current ?? 1,
          page_size: res?.size ?? 10,
          total: res?.total ?? 0,
        },
      });
    } catch (error) {
      message.warning('请求超时');
    }

    setLoading(false);
  };

  /**
   * 表单搜索
   * 此处可过滤数据并可等待其中异步操作，所以此过滤需要返回一个promise
   */
  const submit = (values: any, error: any[]) => {
    if (error.length) return;

    const search = values;
    // 过滤为空的数据
    Object.keys(search).forEach((item) => {
      !search[item] && delete search[item];
    });
    const submitValue = { ...reqData, search, page: { page: 1 } };

    setReqData(submitValue);
  };

  /**
   * 分页器事件
   * @param page
   * @param page_size
   */
  const handlePageChange = (page: number, pageSize: number) => {
    // console.log(page, page_size);
    setReqData({ ...reqData, page: { ...reqData.page, page, page_size: pageSize } });
  };

  /**
   * 分页器配置
   */
  const pagination = {
    onChange: handlePageChange,
    onShowSizeChange: handlePageChange,
    total: tableData.total,
    pageSize: tableData.page_size,
    current: tableData.page,
    showSizeChanger: true,
    showTotal: (total: number) => `共${total}条`,
  };

  /**
   * 一级tabs切换，传入onChange函数则会将该函数的返回值设为请求参数
   * @param key key值
   * @param value value
   */
  const firstTabsChange = (key: any, value: any) => {
    // 默认为当前选中的值若无手动选中，则为二级tabs默认值
    const secondValue = reqData.search[tabs?.secondTabs?.key || '']
      ? reqData.search[tabs?.secondTabs?.key || '']
      : tabs?.secondTabs?.data.find((item) => item.key === tabs?.secondTabs?.defaultKey)?.key;
    const reqValue = {
      ...reqData,
      search: { [tabs?.secondTabs?.key || '']: secondValue, [key]: value },
    };
    // const reqValue = { ...reqData, search: { [key]: value } }
    if (typeof tabs?.firstTabs?.onChange === 'function') {
      const result = tabs?.firstTabs.onChange(key, value, reqValue);
      result ? setReqData({ ...result }) : setReqData(reqValue);
      return;
    }
    setReqData(reqValue);
  };

  /**
   * 二级tabs切换，传入onChange函数则会将该函数的返回值设为请求参数
   * 二级tabs切换会带上一级tabs的值
   * @param key key值
   * @param value value
   */
  const secondTabsChange = (key: any, value: any) => {
    // 默认为当前选中的值若无手动选中，则为一级tabs默认值
    const firstTabsValue = reqData.search[tabs?.firstTabs?.key || '']
      ? reqData.search[tabs?.firstTabs?.key || '']
      : tabs?.firstTabs?.data?.find((item) => item.key === tabs?.firstTabs?.defaultKey)?.key;
    const reqValue = {
      ...reqData,
      search: { [tabs?.firstTabs?.key || '']: firstTabsValue, [key]: value },
    };
    if (typeof tabs?.secondTabs?.onChange === 'function') {
      const result = tabs?.secondTabs.onChange(key, value, reqValue);
      result ? setReqData({ ...result }) : setReqData(reqValue);
      return;
    }
    setReqData(reqValue);
    // console.log('secondTabsChange', key, value);
  };

  // 多选配置
  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectRowKeys,
    hideOnSinglePage: true,
    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
      setSelectRowKeys(selectedRowKeys);
      setSelectRows(selectedRows);
    },
  };

  return (
    <div className='pro-table-wrap'>
      <ProTableHeader
        title={pageTitle}
        tabs={tabs}
        firstTabsChange={firstTabsChange}
        secondTabsChange={secondTabsChange}
      />
      {schema ? (
        <div className='bg-white rounded-md shadow-sm p-5 mb-5'>
          <FormRender form={form} schema={schema!} onFinish={submit} {...formProps} />
          <div className='flex justify-end'>
            <Space>
              <Button
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                重置
              </Button>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </div>
        </div>
      ) : // <Form formProps={props.formProps} submit={submit} circle={!(tabs && tabs.secondTabs)} />
      null}
      <div className='pro-table-body-wrap'>
        {tableTools && renderTools(tableTools, selectRows)}

        <Table
          {...otherTableProps}
          {...other}
          dataSource={tableData.list}
          rowSelection={props.row && rowSelection}
          size='middle'
          onChange={onChange}
          loading={loading}
          pagination={pagination.current && pagination.pageSize ? pagination : false}
        />
      </div>
    </div>
  );
});

/**
 * 渲染表格工具栏
 * @param tableTools 表格tools工具栏配置
 * @param selectRows 当前选中行数据
 */
const renderTools = (tableTools: AppTableProps['tableTools'], selectRows: any[]) => {
  return (
    <div className='pro-table-tools'>
      <div className='pro-table-tools-title'>
        {tableTools?.title ? (
          <>
            <span />
            <span>{tableTools.title}</span>
          </>
        ) : null}
      </div>
      <div className='pro-table-tools-actions-wrap'>
        {tableTools?.actions.map((item, index) => (
          <div key={index}>{item?.render(selectRows)}</div>
        ))}
      </div>
    </div>
  );
};

/**
 * 获取tabs默认请求数据
 * @param tabs tabs配置值
 */
const getTabsInitReq = (tabs: any) => {
  let tabsReq: any = {};

  // 一级tabs默认请求数据
  if (tabs.firstTabs?.defaultKey !== null) {
    tabsReq = {
      [tabs.firstTabs?.key]: tabs.firstTabs?.data.find((item: any) => item.key === tabs.firstTabs.defaultKey)?.key,
    };
  } else {
    tabsReq = { [tabs.firstTabs?.key]: tabs.firstTabs?.data[0]?.key };
  }

  // 二级tabs默认请求数据
  if (tabs.secondTabs?.defaultKey !== null) {
    tabsReq = {
      ...tabsReq,
      [tabs.secondTabs?.key]: tabs.secondTabs?.data.find((item: any) => item.key === tabs.secondTabs.defaultKey)?.key,
    };
  } else {
    tabsReq = {
      ...tabsReq,
      [tabs.secondTabs?.key]: tabs.secondTabs?.data[0]?.key,
    };
  }

  /**
   * 过滤空值
   */
  Object.keys(tabsReq).forEach((item) => {
    !tabsReq[item] && delete tabsReq[item];
  });

  return tabsReq;
};
