/* eslint-disable */
/* tslint:disable */
/** Do not modify manually.
content is generated automatically by `ts-gear`. */
import { requester as requester } from "../request/index";
import type {
  LoginResponse,
  Consumer,
  PageScoreTransaction,
  SportStatistics,
  PageGame,
  Game,
  LoginRequest,
  SportRecord,
  ConsumerUpdateDto,
  PageSkippingStatistic,
  PageSysUser,
  PageSportRecord,
  SysUser,
  PageHealthRecord,
  Contest,
  PageConsumer,
  PageContest,
  ContestReq,
  ContestDto,
  ConsumerAddress,
  ContestType,
  District,
  FileDto,
} from "./definition";

/** @description request parameter type for postApiConsumerWxMiniAuthCode */
export interface PostApiConsumerWxMiniAuthCodeOption {
  /**
   * @description
   * @example
   *
   */
  query: {
    /**
        @description
        @example
           */
    code: string;
  };
}

/** @description request parameter type for postApiConsumerWxMiniAuthCode */
export interface PostApiConsumerWxMiniAuthCodeOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for postApiConsumerWxMiniAuthCode */
export interface PostApiConsumerWxMiniAuthCodeResponse {
  /**
   * @description
   *   OK
   */
  200: LoginResponse;
}

export type PostApiConsumerWxMiniAuthCodeResponseSuccess =
  PostApiConsumerWxMiniAuthCodeResponse[200];
/**
 * @description
 *   小程序登录接口
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457532-run"
 */
export const postApiConsumerWxMiniAuthCode = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/consumer/wx/mini/auth/code";
  function request(
    option: PostApiConsumerWxMiniAuthCodeOption
  ): Promise<PostApiConsumerWxMiniAuthCodeResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiConsumerWxMiniAuthCodeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiConsumerCurrent */
export interface GetApiConsumerCurrentOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiConsumerCurrent */
export interface GetApiConsumerCurrentResponse {
  /**
   * @description
   *   OK
   */
  200: Consumer;
}

export type GetApiConsumerCurrentResponseSuccess =
  GetApiConsumerCurrentResponse[200];
/**
 * @description
 *   获取当前登陆用户信息
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457528-run"
 */
export const getApiConsumerCurrent = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/consumer/current";
  function request(
    option: GetApiConsumerCurrentOption
  ): Promise<GetApiConsumerCurrentResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiConsumerCurrentResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiScoreTransaction */
export interface GetApiScoreTransactionOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
    /**
        @description
        @example
           */
    type?: string;
  };
}

/** @description request parameter type for getApiScoreTransaction */
export interface GetApiScoreTransactionOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiScoreTransaction */
export interface GetApiScoreTransactionResponse {
  /**
   * @description
   *   OK
   */
  200: PageScoreTransaction;
}

export type GetApiScoreTransactionResponseSuccess =
  GetApiScoreTransactionResponse[200];
/**
 * @description
 *   运动账户流水列表
 * @tags api-账户流水
 * @x-apifox-folder "api-账户流水"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457544-run"
 */
export const getApiScoreTransaction = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/scoreTransaction";
  function request(
    option: GetApiScoreTransactionOption
  ): Promise<GetApiScoreTransactionResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiScoreTransactionResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiSportRecordStatistics */
export interface GetApiSportRecordStatisticsOption {
  /**
   * @description
   *   开始日期 包含
   * @example
   *
   */
  query?: {
    /**
        @description
          开始日期 包含
        @example
           */
    start?: string;
    /**
        @description
          结束日期 包含
        @example
           */
    end?: string;
    /**
        @description
          运动类型
        @example
           */
    sportType?: string;
  };
}

/** @description request parameter type for getApiSportRecordStatistics */
export interface GetApiSportRecordStatisticsOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiSportRecordStatistics */
export interface GetApiSportRecordStatisticsResponse {
  /**
   * @description
   *   OK
   */
  200: SportStatistics;
}

export type GetApiSportRecordStatisticsResponseSuccess =
  GetApiSportRecordStatisticsResponse[200];
/**
 * @description
 *   运动记录统计
 * @tags api-运动记录
 * @x-apifox-folder "api-运动记录"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457530-run"
 */
export const getApiSportRecordStatistics = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/sportRecord/statistics";
  function request(
    option: GetApiSportRecordStatisticsOption
  ): Promise<GetApiSportRecordStatisticsResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiSportRecordStatisticsResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiGame */
export interface GetApiGameOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getApiGame */
export interface GetApiGameOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiGame */
export interface GetApiGameResponse {
  /**
   * @description
   *   OK
   */
  200: PageGame;
}

export type GetApiGameResponseSuccess = GetApiGameResponse[200];
/**
 * @description
 *   游戏列表
 * @tags api-游戏模块
 * @x-apifox-folder "api-游戏模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457537-run"
 */
export const getApiGame = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/game";
  function request(
    option: GetApiGameOption
  ): Promise<GetApiGameResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiGameResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiGameId */
export interface GetApiGameIdOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    id: number;
  };
}

/** @description request parameter type for getApiGameId */
export interface GetApiGameIdOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiGameId */
export interface GetApiGameIdResponse {
  /**
   * @description
   *   OK
   */
  200: Game;
}

export type GetApiGameIdResponseSuccess = GetApiGameIdResponse[200];
/**
 * @description
 *   游戏详情
 * @tags api-游戏模块
 * @x-apifox-folder "api-游戏模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457547-run"
 */
export const getApiGameId = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/game/:id";
  function request(
    option: GetApiGameIdOption
  ): Promise<GetApiGameIdResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiGameIdResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiConsumerPhonePhoneCode */
export interface GetApiConsumerPhonePhoneCodeOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    phone: string;
  };
}

/** @description request parameter type for getApiConsumerPhonePhoneCode */
export interface GetApiConsumerPhonePhoneCodeOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiConsumerPhonePhoneCode */
export interface GetApiConsumerPhonePhoneCodeResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type GetApiConsumerPhonePhoneCodeResponseSuccess =
  GetApiConsumerPhonePhoneCodeResponse[200];
/**
 * @description
 *   发送手机验证码
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457538-run"
 */
export const getApiConsumerPhonePhoneCode = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/consumer/phone/:phone/code";
  function request(
    option: GetApiConsumerPhonePhoneCodeOption
  ): Promise<GetApiConsumerPhonePhoneCodeResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiConsumerPhonePhoneCodeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiConsumerLogin */
export interface PostApiConsumerLoginOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for postApiConsumerLogin */
export interface PostApiConsumerLoginOption {
  body?: LoginRequest;
}

/** @description response type for postApiConsumerLogin */
export interface PostApiConsumerLoginResponse {
  /**
   * @description
   *   OK
   */
  200: LoginResponse;
}

export type PostApiConsumerLoginResponseSuccess =
  PostApiConsumerLoginResponse[200];
/**
 * @description
 *   登陆接口
 * @tags 用户模块
 * @x-apifox-folder "用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-33323270-run"
 */
export const postApiConsumerLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/consumer/login";
  function request(
    option: PostApiConsumerLoginOption
  ): Promise<PostApiConsumerLoginResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiConsumerLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiSportRecord */
export interface PostApiSportRecordOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for postApiSportRecord */
export interface PostApiSportRecordOption {
  body?: SportRecord;
}

/** @description response type for postApiSportRecord */
export interface PostApiSportRecordResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PostApiSportRecordResponseSuccess = PostApiSportRecordResponse[200];
/**
 * @description
 *   添加运动记录
 * @tags api-运动记录
 * @x-apifox-folder "api-运动记录"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457546-run"
 */
export const postApiSportRecord = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/sportRecord";
  function request(
    option: PostApiSportRecordOption
  ): Promise<PostApiSportRecordResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiSportRecordResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for putApiConsumer */
export interface PutApiConsumerOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for putApiConsumer */
export interface PutApiConsumerOption {
  body?: ConsumerUpdateDto;
}

/** @description response type for putApiConsumer */
export interface PutApiConsumerResponse {
  /**
   * @description
   *   OK
   */
  200: Consumer;
}

export type PutApiConsumerResponseSuccess = PutApiConsumerResponse[200];
/**
 * @description
 *   更新用户信息
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457540-run"
 */
export const putApiConsumer = /* #__PURE__ */ (() => {
  const method = "put";
  const url = "/api/consumer";
  function request(
    option: PutApiConsumerOption
  ): Promise<PutApiConsumerResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PutApiConsumerResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiConsumerSkippingStatistic */
export interface GetApiConsumerSkippingStatisticOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
    /**
        @description
          1总榜2区域榜
        @example
           */
    type?: number;
    /**
        @description
        @example
           */
    statisticType?: number;
  };
}

/** @description request parameter type for getApiConsumerSkippingStatistic */
export interface GetApiConsumerSkippingStatisticOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiConsumerSkippingStatistic */
export interface GetApiConsumerSkippingStatisticResponse {
  /**
   * @description
   *   OK
   */
  200: PageSkippingStatistic;
}

export type GetApiConsumerSkippingStatisticResponseSuccess =
  GetApiConsumerSkippingStatisticResponse[200];
/**
 * @description
 *   获取跳绳排名
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457527-run"
 */
export const getApiConsumerSkippingStatistic = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/consumer/skipping/statistic";
  function request(
    option: GetApiConsumerSkippingStatisticOption
  ): Promise<GetApiConsumerSkippingStatisticResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiConsumerSkippingStatisticResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebSysUser */
export interface GetWebSysUserOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getWebSysUser */
export interface GetWebSysUserOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebSysUser */
export interface GetWebSysUserResponse {
  /**
   * @description
   *   OK
   */
  200: PageSysUser;
}

export type GetWebSysUserResponseSuccess = GetWebSysUserResponse[200];
/**
 * @description
 *   /web/sysUser
 * @tags admin-系统用户
 * @x-apifox-folder "admin-系统用户"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457529-run"
 */
export const getWebSysUser = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/sysUser";
  function request(
    option: GetWebSysUserOption
  ): Promise<GetWebSysUserResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebSysUserResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebSportRecord */
export interface GetWebSportRecordOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getWebSportRecord */
export interface GetWebSportRecordOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebSportRecord */
export interface GetWebSportRecordResponse {
  /**
   * @description
   *   OK
   */
  200: PageSportRecord;
}

export type GetWebSportRecordResponseSuccess = GetWebSportRecordResponse[200];
/**
 * @description
 *   /web/sportRecord
 * @tags admin-运动记录
 * @x-apifox-folder "admin-运动记录"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457531-run"
 */
export const getWebSportRecord = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/sportRecord";
  function request(
    option: GetWebSportRecordOption
  ): Promise<GetWebSportRecordResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebSportRecordResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebSysUserCurrent */
export interface GetWebSysUserCurrentOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebSysUserCurrent */
export interface GetWebSysUserCurrentResponse {
  /**
   * @description
   *   OK
   */
  200: SysUser;
}

export type GetWebSysUserCurrentResponseSuccess =
  GetWebSysUserCurrentResponse[200];
/**
 * @description
 *   /web/sysUser/current
 * @tags admin-系统用户
 * @x-apifox-folder "admin-系统用户"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457533-run"
 */
export const getWebSysUserCurrent = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/sysUser/current";
  function request(
    option: GetWebSysUserCurrentOption
  ): Promise<GetWebSysUserCurrentResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebSysUserCurrentResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebHealthRecord */
export interface GetWebHealthRecordOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getWebHealthRecord */
export interface GetWebHealthRecordOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebHealthRecord */
export interface GetWebHealthRecordResponse {
  /**
   * @description
   *   OK
   */
  200: PageHealthRecord;
}

export type GetWebHealthRecordResponseSuccess = GetWebHealthRecordResponse[200];
/**
 * @description
 *   /web/healthRecord
 * @tags admin-健康数据模块
 * @x-apifox-folder "admin-健康数据模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457534-run"
 */
export const getWebHealthRecord = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/healthRecord";
  function request(
    option: GetWebHealthRecordOption
  ): Promise<GetWebHealthRecordResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebHealthRecordResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebGame */
export interface GetWebGameOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getWebGame */
export interface GetWebGameOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebGame */
export interface GetWebGameResponse {
  /**
   * @description
   *   OK
   */
  200: PageGame;
}

export type GetWebGameResponseSuccess = GetWebGameResponse[200];
/**
 * @description
 *   /web/game
 * @tags admin-游戏模块
 * @x-apifox-folder "admin-游戏模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457535-run"
 */
export const getWebGame = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/game";
  function request(
    option: GetWebGameOption
  ): Promise<GetWebGameResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebGameResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebContestId */
export interface GetWebContestIdOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    id: number;
  };
}

/** @description request parameter type for getWebContestId */
export interface GetWebContestIdOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebContestId */
export interface GetWebContestIdResponse {
  /**
   * @description
   *   OK
   */
  200: Contest;
}

export type GetWebContestIdResponseSuccess = GetWebContestIdResponse[200];
/**
 * @description
 *   赛事详情
 * @tags admin-赛事模块
 * @x-apifox-folder "admin-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457536-run"
 */
export const getWebContestId = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/contest/:id";
  function request(
    option: GetWebContestIdOption
  ): Promise<GetWebContestIdResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebContestIdResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiSportRecordTodaySkipping */
export interface PostApiSportRecordTodaySkippingOption {
  /**
   * @description
   * @example
   *
   */
  query: {
    /**
        @description
        @example
           */
    skippingNum: number;
  };
}

/** @description request parameter type for postApiSportRecordTodaySkipping */
export interface PostApiSportRecordTodaySkippingOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for postApiSportRecordTodaySkipping */
export interface PostApiSportRecordTodaySkippingResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PostApiSportRecordTodaySkippingResponseSuccess =
  PostApiSportRecordTodaySkippingResponse[200];
/**
 * @description
 *   添加今日跳绳数
 * @tags api-运动记录
 * @x-apifox-folder "api-运动记录"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457539-run"
 */
export const postApiSportRecordTodaySkipping = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/sportRecord/today/skipping";
  function request(
    option: PostApiSportRecordTodaySkippingOption
  ): Promise<PostApiSportRecordTodaySkippingResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiSportRecordTodaySkippingResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebConsumer */
export interface GetWebConsumerOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
    /**
        @description
        @example
           */
    phone?: string;
  };
}

/** @description request parameter type for getWebConsumer */
export interface GetWebConsumerOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebConsumer */
export interface GetWebConsumerResponse {
  /**
   * @description
   *   OK
   */
  200: PageConsumer;
}

export type GetWebConsumerResponseSuccess = GetWebConsumerResponse[200];
/**
 * @description
 *   /web/consumer
 * @tags admin-用户模块
 * @x-apifox-folder "admin-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457541-run"
 */
export const getWebConsumer = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/consumer";
  function request(
    option: GetWebConsumerOption
  ): Promise<GetWebConsumerResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebConsumerResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebConsumerId */
export interface GetWebConsumerIdOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    id: number;
  };
}

/** @description request parameter type for getWebConsumerId */
export interface GetWebConsumerIdOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebConsumerId */
export interface GetWebConsumerIdResponse {
  /**
   * @description
   *   OK
   */
  200: Consumer;
}

export type GetWebConsumerIdResponseSuccess = GetWebConsumerIdResponse[200];
/**
 * @description
 *   /web/consumer/{id}
 * @tags admin-用户模块
 * @x-apifox-folder "admin-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457542-run"
 */
export const getWebConsumerId = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/consumer/:id";
  function request(
    option: GetWebConsumerIdOption
  ): Promise<GetWebConsumerIdResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebConsumerIdResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebContest */
export interface GetWebContestOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getWebContest */
export interface GetWebContestOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebContest */
export interface GetWebContestResponse {
  /**
   * @description
   *   OK
   */
  200: PageContest;
}

export type GetWebContestResponseSuccess = GetWebContestResponse[200];
/**
 * @description
 *   赛事列表
 * @tags admin-赛事模块
 * @x-apifox-folder "admin-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457543-run"
 */
export const getWebContest = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/contest";
  function request(
    option: GetWebContestOption
  ): Promise<GetWebContestResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebContestResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for putWebContest */
export interface PutWebContestOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for putWebContest */
export interface PutWebContestOption {
  body?: ContestReq;
}

/** @description response type for putWebContest */
export interface PutWebContestResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PutWebContestResponseSuccess = PutWebContestResponse[200];
/**
 * @description
 *   更新赛事
 * @tags admin-赛事模块
 * @x-apifox-folder "admin-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240583-run"
 */
export const putWebContest = /* #__PURE__ */ (() => {
  const method = "put";
  const url = "/web/contest";
  function request(
    option: PutWebContestOption
  ): Promise<PutWebContestResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PutWebContestResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postWebContest */
export interface PostWebContestOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for postWebContest */
export interface PostWebContestOption {
  body?: ContestReq;
}

/** @description response type for postWebContest */
export interface PostWebContestResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PostWebContestResponseSuccess = PostWebContestResponse[200];
/**
 * @description
 *   添加赛事
 * @tags admin-赛事模块
 * @x-apifox-folder "admin-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240584-run"
 */
export const postWebContest = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/web/contest";
  function request(
    option: PostWebContestOption
  ): Promise<PostWebContestResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostWebContestResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postWebSysUserLogin */
export interface PostWebSysUserLoginOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for postWebSysUserLogin */
export interface PostWebSysUserLoginOption {
  body?: LoginRequest;
}

/** @description response type for postWebSysUserLogin */
export interface PostWebSysUserLoginResponse {
  /**
   * @description
   *   OK
   */
  200: LoginResponse;
}

export type PostWebSysUserLoginResponseSuccess =
  PostWebSysUserLoginResponse[200];
/**
 * @description
 *   /web/sysUser/login
 * @tags admin-系统用户
 * @x-apifox-folder "admin-系统用户"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457545-run"
 */
export const postWebSysUserLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/web/sysUser/login";
  function request(
    option: PostWebSysUserLoginOption
  ): Promise<PostWebSysUserLoginResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostWebSysUserLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiContest */
export interface GetApiContestOption {
  /**
   * @description
   * @example
   *
   */
  query?: {
    /**
        @description
        @example
           */
    pageNum?: number;
    /**
        @description
        @example
           */
    pageSize?: number;
    /**
        @description
        @example
           */
    orderBy?: string;
    /**
        @description
        @example
           */
    count?: string;
  };
}

/** @description request parameter type for getApiContest */
export interface GetApiContestOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiContest */
export interface GetApiContestResponse {
  /**
   * @description
   *   OK
   */
  200: PageContest;
}

export type GetApiContestResponseSuccess = GetApiContestResponse[200];
/**
 * @description
 *   赛事列表
 * @tags api-赛事模块
 * @x-apifox-folder "api-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457548-run"
 */
export const getApiContest = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/contest";
  function request(
    option: GetApiContestOption
  ): Promise<GetApiContestResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiContestResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiContestId */
export interface GetApiContestIdOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    id: number;
  };
}

/** @description request parameter type for getApiContestId */
export interface GetApiContestIdOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiContestId */
export interface GetApiContestIdResponse {
  /**
   * @description
   *   OK
   */
  200: ContestDto;
}

export type GetApiContestIdResponseSuccess = GetApiContestIdResponse[200];
/**
 * @description
 *   赛事详情
 * @tags api-赛事模块
 * @x-apifox-folder "api-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-38457549-run"
 */
export const getApiContestId = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/contest/:id";
  function request(
    option: GetApiContestIdOption
  ): Promise<GetApiContestIdResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiContestIdResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiConsumerAddressCurrentDefault */
export interface GetApiConsumerAddressCurrentDefaultOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiConsumerAddressCurrentDefault */
export interface GetApiConsumerAddressCurrentDefaultResponse {
  /**
   * @description
   *   OK
   */
  200: ConsumerAddress;
}

export type GetApiConsumerAddressCurrentDefaultResponseSuccess =
  GetApiConsumerAddressCurrentDefaultResponse[200];
/**
 * @description
 *   获取用户默认地址
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240581-run"
 */
export const getApiConsumerAddressCurrentDefault = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/consumer/address/current/default";
  function request(
    option: GetApiConsumerAddressCurrentDefaultOption
  ): Promise<GetApiConsumerAddressCurrentDefaultResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiConsumerAddressCurrentDefaultResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getWebContestType */
export interface GetWebContestTypeOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getWebContestType */
export interface GetWebContestTypeResponse {
  /**
   * @description
   *   OK
   */
  200: Array<ContestType>;
}

export type GetWebContestTypeResponseSuccess = GetWebContestTypeResponse[200];
/**
 * @description
 *   赛事类型列表
 * @tags admin-赛事模块
 * @x-apifox-folder "admin-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240582-run"
 */
export const getWebContestType = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/web/contest/type";
  function request(
    option: GetWebContestTypeOption
  ): Promise<GetWebContestTypeResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetWebContestTypeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiContestIdEnroll */
export interface PostApiContestIdEnrollOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    id: number;
  };
}

/** @description request parameter type for postApiContestIdEnroll */
export interface PostApiContestIdEnrollOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for postApiContestIdEnroll */
export interface PostApiContestIdEnrollResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PostApiContestIdEnrollResponseSuccess =
  PostApiContestIdEnrollResponse[200];
/**
 * @description
 *   赛事报名
 * @tags api-赛事模块
 * @x-apifox-folder "api-赛事模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240585-run"
 */
export const postApiContestIdEnroll = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/contest/:id/enroll";
  function request(
    option: PostApiContestIdEnrollOption
  ): Promise<PostApiContestIdEnrollResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiContestIdEnrollResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for putApiConsumerAddress */
export interface PutApiConsumerAddressOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for putApiConsumerAddress */
export interface PutApiConsumerAddressOption {
  body?: ConsumerAddress;
}

/** @description response type for putApiConsumerAddress */
export interface PutApiConsumerAddressResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PutApiConsumerAddressResponseSuccess =
  PutApiConsumerAddressResponse[200];
/**
 * @description
 *   更新地址
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240587-run"
 */
export const putApiConsumerAddress = /* #__PURE__ */ (() => {
  const method = "put";
  const url = "/api/consumer/address";
  function request(
    option: PutApiConsumerAddressOption
  ): Promise<PutApiConsumerAddressResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PutApiConsumerAddressResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiConsumerAddress */
export interface PostApiConsumerAddressOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description request parameter type for postApiConsumerAddress */
export interface PostApiConsumerAddressOption {
  body?: ConsumerAddress;
}

/** @description response type for postApiConsumerAddress */
export interface PostApiConsumerAddressResponse {
  /**
   * @description
   *   OK
   */
  200: {};
}

export type PostApiConsumerAddressResponseSuccess =
  PostApiConsumerAddressResponse[200];
/**
 * @description
 *   添加地址
 * @tags api-用户模块
 * @x-apifox-folder "api-用户模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240586-run"
 */
export const postApiConsumerAddress = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/consumer/address";
  function request(
    option: PostApiConsumerAddressOption
  ): Promise<PostApiConsumerAddressResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostApiConsumerAddressResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for getApiDictDistrictParentParentId */
export interface GetApiDictDistrictParentParentIdOption {
  /**
   * @description
   * @example
   *
   */
  path: {
    /**
        @description
        @example
           */
    parentId: number;
  };
}

/** @description request parameter type for getApiDictDistrictParentParentId */
export interface GetApiDictDistrictParentParentIdOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for getApiDictDistrictParentParentId */
export interface GetApiDictDistrictParentParentIdResponse {
  /**
   * @description
   *   OK
   */
  200: Array<District>;
}

export type GetApiDictDistrictParentParentIdResponseSuccess =
  GetApiDictDistrictParentParentIdResponse[200];
/**
 * @description
 *   parentId传0获取省列表
 *   获取地区信息
 * @tags api-字典模块
 * @x-apifox-folder "api-字典模块"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40240604-run"
 */
export const getApiDictDistrictParentParentId = /* #__PURE__ */ (() => {
  const method = "get";
  const url = "/api/dict/district/parent/:parentId";
  function request(
    option: GetApiDictDistrictParentParentIdOption
  ): Promise<GetApiDictDistrictParentParentIdResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<GetApiDictDistrictParentParentIdResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postWebUpload */
export interface PostWebUploadOption {
  /**
   * @description
   * @example
   *
   */
  query: {
    /**
        @description
        @example
           */
    request: string;
  };
}

/** @description request parameter type for postWebUpload */
export interface PostWebUploadOption {
  /**
   * @description
   * @example
   *   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8
   */
  header?: {
    /**
        @description
        @example
          eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiYXVkIjpbIndlYiJdLCJpYXQiOjE2NjMzODEwODQsImV4cCI6MTY3MjAyMTA4NH0.rBVtCR6stTLQ8Z6cvHEXc7XTSv-vFqU54OmoOdR7b-8 */
    "x-token": string;
  };
}

/** @description response type for postWebUpload */
export interface PostWebUploadResponse {
  /**
   * @description
   *   OK
   */
  200: FileDto;
}

export type PostWebUploadResponseSuccess = PostWebUploadResponse[200];
/**
 * @description
 *   /web/upload
 * @tags upload-ctrl
 * @x-apifox-folder "upload-ctrl"
 * @x-apifox-status "released"
 * @x-run-in-apifox "https://www.apifox.cn/web/project/1419820/apis/api-40416341-run"
 */
export const postWebUpload = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/web/upload";
  function request(
    option: PostWebUploadOption
  ): Promise<PostWebUploadResponseSuccess> {
    return requester(request.url, {
      method: request.method,
      ...option,
    }) as unknown as Promise<PostWebUploadResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();
