import React, { memo, useEffect, useState, useRef } from 'react';
import { Breadcrumb, Space, Popconfirm, Menu, Dropdown, Modal, Form, Input, message, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  SearchOutlined,
  BellOutlined,
  GithubOutlined,
  PoweroffOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';
import routersAll, { AppRouteObject } from '@/routes';
// import { getProfileDetailApi, editUserPasswordApi } from "@/services/global.js";
// import sha1 from '@/utils/sha1';
// import { editProfileApi, getProfileDetailApi } from '@/services';
// import Logo from '@/assets/images/logo.png'
import './index.less';
import { TOKEN } from '@/constants';

const { confirm } = Modal;

export default memo(function ({ pathname, toggle }: { pathname: string; toggle: () => void }) {
  const navigate = useNavigate();
  // const [breadcrumb, setBreadcrumb] = useState();

  // useEffect(() => {
  //   const tag = renderTag(routers, pathname);
  //   setBreadcrumb(tag);
  // }, [pathname]);

  const handleLogout = () => {
    confirm({
      title: '是否确认退出登录',
      onOk() {
        localStorage.removeItem(TOKEN);
        navigate('/login');
      },
    });
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item
  //       key='0'
  //       onClick={() => {
  //         console.log('修改密码');
  //         setVisible(true);
  //       }}
  //     >
  //       <span>修改密码</span>
  //     </Menu.Item>
  //     <Menu.Item
  //       key='1'
  //       onClick={() => {
  //         console.log('退出登陆');
  //         logOut();
  //       }}
  //     >
  //       <span>退出登陆</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const handleOk = () => {
  //   // 数据验证
  //   editRef.current.validateFields().then(
  //     async (values) => {
  //       if (values.new_password !== values.new_password2) {
  //         return message.warning('两次输入密码不一致');
  //       }

  //       delete values.new_password2;
  //       values.new_password = sha1(values.new_password);
  //       values.old_password = sha1(values.old_password);

  //       const { code, data, msg } = await editProfileApi(values);
  //       if (code === 0) {
  //         // 刷新列表
  //         setVisible(false);
  //         message.success('修改成功');
  //         setTimeout(() => {
  //           localStorage.removeItem('jianfa-car-authorization');
  //           navigate('/login');
  //         }, 100);
  //       } else {
  //         message.error(msg);
  //       }
  //     },
  //     () => {
  //       // 数据验证失败
  //     },
  //   );
  // };

  // const handleCancel = () => {
  //   editRef.current.resetFields();
  //   setVisible(false);
  // };

  return (
    <header className='layout-header-wrap bg-white flex justify-between align-center'>
      <div className='flex align-center'>
        <Space>
          <MenuUnfoldOutlined onClick={toggle} />
          {/* <Breadcrumb>
            {breadcrumb &&
              breadcrumb.map((item) => {
                return (
                  <Breadcrumb.Item key={item.path}>
                    <span>{item.title}</span>
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb> */}
        </Space>
      </div>
      <div className='layout-header-right flex align-center'>
        {/* <div className='userinfo flex align-center'>
          <Dropdown overlay={menu} trigger={['click', 'hover']}>
            <div className='ant-dropdown-link text-000 cursor-pointer'>
              <UserOutlined style={{ fontSize: 18 }} />
              <DownOutlined />
            </div>
          </Dropdown>
        </div> */}

        {/* <Popconfirm title='确定退出登录吗？' okText='确定' cancelText='取消' placement='bottomRight' onConfirm={logOut}> */}
        <PoweroffOutlined className='poweroffOutlined' onClick={handleLogout} />
        {/* </Popconfirm> */}
      </div>

      {/* <Modal title='修改密码' visible={visible} onOk={handleOk} onCancel={handleCancel} width={400}>
        <Form ref={editRef}>
          <Form.Item label='旧密码' name='old_password' rules={[{ required: true, message: '请输入！' }]}>
            <Input placeholder='请输入密码' type='password' allowClear />
          </Form.Item>
          <Form.Item label='新密码' name='new_password' rules={[{ required: true, message: '请输入！' }]}>
            <Input placeholder='请输入密码' type='password' allowClear />
          </Form.Item>
          <Form.Item
            label='确认密码'
            name='new_password2'
            rules={[
              { required: true, message: '请输入！' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('两次输入密码不匹配');
                },
              }),
            ]}
          >
            <Input placeholder='请输入密码' type='password' allowClear />
          </Form.Item>
        </Form>
      </Modal> */}
    </header>
  );
});

// /**
//  * 生成面包屑
//  * @param {Array} router 路由数组
//  */
// const renderTag = (router, pathname) => {
//   let paths = [];
//   // console.log(router, pathname);
//   router.forEach((item) => {
//     if (item.path === pathname) {
//       paths = [{ path: item.path, title: item.title }];
//     } else if (item.children && pathname.includes(item.path)) {
//       paths = [...paths, { path: item.path, title: item.title }, ...renderTag(item.children, pathname)];
//     }
//   });

//   return paths;
// };
