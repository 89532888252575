import { combineReducers } from '@reduxjs/toolkit';

// import taskBoardReducer from 'src/pages/task-board/store/slice';
// import stageBoardReducer from 'src/pages/stage-board/store/slice';
// import projectReducer from 'src/pages/project/store/slice';
// import profileReducer from 'src/pages/login/store/slice';

/**
 * 合并reducers
 */
const reducers = {
  // taskStore: taskBoardReducer,
  // stageStore: stageBoardReducer,
  // projectStore: projectReducer,
  // profileStore: profileReducer,
};

const reducer = combineReducers(reducers);
export default reducer;
