import axios from 'axios';
import type { AxiosRequestConfig, Method } from 'axios';
import * as pathToRegexp from 'path-to-regexp';
import { message } from 'antd';
import type { Requester, RequestParameter } from 'ts-gear';

import { TOKEN } from '@/constants';
import { BASE_URL, TIME_OUT } from '../config';

export const parseUrl = (url: string, option: RequestParameter): string => {
  if (option.path) {
    Object.getOwnPropertyNames(option.path).forEach((k) => {
      option.path[k] = encodeURIComponent(String(option.path[k]));
    });
    url = pathToRegexp.compile(url)(option.path);
  }
  if (option.query) {
    const onlyPathname = false;
    // if (!url.startsWith('http:') || !url.startsWith('https:')) {
    //   url = `http://localhost${url}`;
    //   onlyPathname = true;
    // }
    const urlObject = new URL(url);
    const search = new URLSearchParams(urlObject.search);
    Object.getOwnPropertyNames(option.query).forEach((k) => {
      const v = option.query[k];
      if (Array.isArray(v)) {
        v.forEach((item, i) => {
          if (i === 0) {
            search.set(k, item);
          } else {
            search.append(k, item);
          }
        });
      } else {
        search.set(k, v);
      }
    });
    const searchString = search.toString();
    url = `${onlyPathname ? '' : urlObject.origin}${urlObject.pathname}${searchString ? '?' : ''}${searchString}`;
  }
  return url;
};

export function interceptRequest(url: string, option?: RequestParameter): [string, AxiosRequestConfig] {
  // url = parseUrl(url, option);
  option = option || {};
  const requestOption: AxiosRequestConfig = {
    method: (option.method as any) || 'get',
  };
  if (option.header) {
    requestOption.headers = option.header;
  }
  if (option.body) {
    requestOption.data = option.body;
  }
  if (option.formData) {
    const formData = new FormData();
    // 这种上传文件的情况，应该只有一维的键值对应，只用forEach处理第一层数据
    // forEach(option.formData, (v: any, k: string) => {
    //   formData.append(k, v);
    // });
    // requestOption.data = formData;
  }
  return [url, requestOption];
}

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
});

// 响应拦截
instance.interceptors.response.use((res) => {
  // console.log(res.data);
  // debugger;

  // if (res.data.code === 'B_0000005') {
  //   message.warning(res.data.msg ?? '请求超时');
  //   console.error('请求超时');

  //   // debugger;
  //   localStorage.removeItem(TOKEN);
  //   window.location.replace('/#/login/');

  //   // /api/v1/api/program/export 接口会直接返回文件
  // }

  // console.log('res.data:', res);

  return res.data;
});

// 请求拦截
instance.interceptors.request.use(
  (res) => {
    // const token =localStorage.getItem(TOKEN)
    if (!res.headers!['x-token']) {
      res.headers!['x-token'] = localStorage.getItem(TOKEN) ?? '';
      // res.headers!.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`;
      // res.headers!.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXNzYWdlIjp7ImFnZW50X2FjY291bnRfaWQiOiIxIiwidG9rZW5fZmxhZyI6IlB6UGhZM3h5Qng3WG9JU2sifSwiZXhwIjoxNjUwMjkwMjE3fQ.nBNGklnF46w7B85F1V5AWHHoq-cKfA3puHQxSdX1GDk`;
    } else if (!res?.url?.includes('login')) {
      window.location.replace('/#/login/');
    }

    return res;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const requester: Requester = async (apiUrl: string, param?: any) => {
  const [url, option] = interceptRequest(parseUrl(apiUrl, param || {}), param);
  return new Promise((resolve, reject) => {
    instance(url, option)
      .then((res: any) => {
        // console.log('instance:', res);
        // debugger;

        if (res.code!) reject(res);
        resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          // console.log(err.response);

          reject(err.response);
          console.warn(`请求超时 -`, err.response.data.error);
          message.warning(`请求超时 - ${err.response.data.error}`);

          // 错误信息
          console.log(err.response);
        }
      });
  });
  // return instance(url, option);
};
