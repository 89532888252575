import { Navigate } from 'react-router-dom';
import Routes from '@/routes';
import { AppLayout } from '@/components';
import { TOKEN } from './constants';

function App() {
  // 未登录
  if (!localStorage.getItem(TOKEN)) return <Navigate to='/login' replace />;

  return (
    <>
      {/* {useRoutes(Routes as RouteObject[])} */}
      <AppLayout />
      {/* <Outlet /> */}
    </>
  );
}

export default App;
