import { useLocation } from 'react-router-dom';

export const parseQuery = (queryStr: string) => {
  const obj: Record<string, any> = {};
  queryStr = queryStr.slice(1);
  queryStr.split('&').forEach((item) => {
    const [key, val] = item.split('=');
    // 逗号分割 表示是数组
    if (val) obj[key] = val.includes(',') ? val.split(',') : decodeURIComponent(val);
    else obj[key] = undefined;
  });

  return obj;
};

export function useAppParams<T extends Record<string, any>>() {
  const params = parseQuery(useLocation().search);
  return params as T;
}
