export const TOKEN = 'little-bear-movement-admin-token';

// export * from './bet';
export const BetType = {
  AWAIT: 1,
  SUCCESS: 4,
  ACTIVE: 3,
};

export const BetTypeList = [
  { label: '待开奖', value: 1 },
  { label: '发奖中', value: 3 },
  { label: '已开奖', value: 4 },
  { label: '未中奖', value: 10 },
  { label: '待退回', value: 11 },
  { label: '退回中', value: 12 },
  { label: '已退回', value: 13 },
];
