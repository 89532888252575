// let proBaseURL = (window as any).getGlobalConfig().url ?? '';

// if (proBaseURL.endsWith('/')) {
//   proBaseURL = proBaseURL.substr(0, proBaseURL.length - 1);
// }

const TIME_OUT = 10000;

const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://skipping-be.windhc.com/' : 'https://skipping-be.windhc.com/';
const TABLE_BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://skipping-be.windhc.com/' : `https://skipping-be.windhc.com/`;

export { BASE_URL, TABLE_BASE_URL, TIME_OUT };
