/* eslint-disable */
/* tslint:disable */
/** Do not modify manually.
content is generated automatically by `ts-gear`. */
import { requester as requester } from "../request/index";
import type {
  SystemSettingListResponse,
  AdminRoleModifyRequest,
  BaseSuccessResponse,
  SystemSettingListRequest,
  AgentAccountModifyRequest,
  GameListRequest,
  GameListResponse,
  AgentGameListRequest,
  ProfileChangePasswordRequest,
  GameModifyRequest,
  OrderDetailRequest,
  OrderDetailResponse,
  AgentCreateRequest,
  OrderMakeRequest,
  UserStatsDailySnapshotDetailRequest,
  UserStatsDailySnapshotDetailResponse,
  UserStatsDailySnapshotListRequest,
  UserStatsDailySnapshotListResponse,
  UserBalanceRecordListRequest,
  UserBalanceRecordListResponse,
  StoreSettingListRequest,
  StoreSettingListResponse,
  AgentAccountDetailResponse,
  AdminAccountListRequest,
  AdminAccountListResponse,
  AgentAccountListRequest,
  AgentAccountListResponse,
  AgentSettingModifyRequest,
  AdminAccountResetRequest,
  AgentModifyRequest,
  UserRegisterRequest,
  UserLoginResponse,
  StoreStatsSnapshotDetailRequest,
  StoreStatsSnapshotDetailResponse,
  AgentSettingListRequest,
  AgentSettingListResponse,
  AdminRoleRemoveRequest,
  BlacklistCreateRequest,
  AdminPermissionListRequest,
  AdminPermissionListResponse,
  GenerateAgentStatsRequest,
  AdminAccountModifyRequest,
  WalletBalanceResponse,
  AgentStatsDailySnapshotDetailRequest,
  AgentStatsDailySnapshotDetailResponse,
  AgentRelationDetailRequest,
  AgentRelationDetailResponse,
  UserRelationListRequest,
  UserRelationListResponse,
  CoinListRequest,
  CoinListResponse,
  WithdrawOrderModifyRequest,
  AgentStatsSnapshotDetailRequest,
  AgentStatsSnapshotDetailResponse,
  StoreStatsDailySnapshotListRequest,
  StoreStatsDailySnapshotListResponse,
  AgentAccountDetailRequest,
  AgentRemoveRequest,
  UserDetailRequest,
  UserDetailResponse,
  AdminRoleCreateRequest,
  AgentAccountRemoveRequest,
  WithdrawOrderCreateRequest,
  WithdrawOrderCancelRequest,
  ProfileLoginRequest,
  ProfileLoginResponse,
  AgentListRequest,
  AgentListResponse,
  AdminAccountCreateRequest,
  AgentStatsDailySnapshotListRequest,
  AgentStatsDailySnapshotListResponse,
  BlacklistListRequest,
  BlacklistListResponse,
  StatsSnapshotDetailRequest,
  StatsSnapshotDetailResponse,
  WithdrawOrderListRequest,
  WithdrawOrderListResponse,
  OrderListRequest,
  OrderListResponse,
  UserListRequest,
  UserListResponse,
  StoreListRequest,
  StoreListResponse,
  ResponseInterface,
  BlacklistRemoveRequest,
  AgentRoleDetailRequest,
  AgentRoleDetailResponse,
  AgentBonusRecordListRequest,
  AgentBonusRecordListResponse,
  WithdrawCallbackRequest,
  AgentAccountCreateRequest,
  AgentRoleModifyRequest,
  AgentDetailRequest,
  AgentDetailResponse,
  UserStatsSnapshotListRequest,
  UserStatsSnapshotListResponse,
  AdminRoleListRequest,
  AdminRoleListResponse,
  CoinModifyRequest,
  UserBalanceDetailRequest,
  UserBalanceDetailResponse,
  StatsDailySnapshotDetailRequest,
  StatsDailySnapshotDetailResponse,
  UserChangePasswordRequest,
  AgentRoleListRequest,
  AgentRoleListResponse,
  AdminAccountRemoveRequest,
  AgentRoleCreateRequest,
  ChargeCallbackRequest,
  StoreCreateRequest,
  UserStatsSnapshotDetailRequest,
  UserStatsSnapshotDetailResponse,
  StoreRemoveRequest,
  DefaultBonusSettingModifyRequest,
  AdminAccountDetailRequest,
  AdminAccountDetailResponse,
  SystemSettingModifyRequest,
  AgentResetRequest,
  StoreSettingModifyRequest,
  DefaultBonusSettingListRequest,
  DefaultBonusSettingListResponse,
  OrderBonusDetailListRequest,
  OrderBonusDetailListResponse,
  AdminRoleDetailRequest,
  AdminRoleDetailResponse,
  UserResetRequest,
  StoreModifyRequest,
  AgentRoleRemoveRequest,
  StoreDetailRequest,
  StoreDetailResponse,
  AgentPermissionListResponse,
  OrderOpenRequest,
  UserLoginRequest,
  UserRelationDetailRequest,
  UserRelationDetailResponse,
  UserBindGoogleAuthRequest,
} from "./definition";

/** @description request parameter type for postApiV1ApiTaskRemove */
export interface PostApiV1ApiTaskRemoveOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          任务ID */
    task_id: string;
  };
}

/** @description response type for postApiV1ApiTaskRemove */
export interface PostApiV1ApiTaskRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskRemoveResponseSuccess =
  PostApiV1ApiTaskRemoveResponse[200];
/**
 * @description
 *   归档任务
 *   归档任务
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/remove";
  function request(
    option?: PostApiV1ApiTaskRemoveOption
  ): Promise<PostApiV1ApiTaskRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultHandle */
export interface PostApiV1ApiTaskResultHandleOption {
  body?: {
    /**
        @description
          任务ID */
    task_id: string;
    /**
        @description
          任务成果ID */
    result_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiTaskResultHandle */
export interface PostApiV1ApiTaskResultHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultHandleResponseSuccess =
  PostApiV1ApiTaskResultHandleResponse[200];
/**
 * @description
 *   操作任务
 *   操作任务
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/handle";
  function request(
    option?: PostApiV1ApiTaskResultHandleOption
  ): Promise<PostApiV1ApiTaskResultHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleDetail */
export interface PostApiV1ApiProgramCycleDetailOption {
  body?: {
    /**
        @description
          周期ID */
    cycle_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: string;
    };
  };
}

/** @description response type for postApiV1ApiProgramCycleDetail */
export interface PostApiV1ApiProgramCycleDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleDetailResponseSuccess =
  PostApiV1ApiProgramCycleDetailResponse[200];
/**
 * @description
 *   获取周期详情
 *   获取周期详情
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/detail";
  function request(
    option?: PostApiV1ApiProgramCycleDetailOption
  ): Promise<PostApiV1ApiProgramCycleDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description response type for postApiV1UserSystemSettingList */
export interface PostApiV1UserSystemSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: SystemSettingListResponse;
  };
}

export type PostApiV1UserSystemSettingListResponseSuccess =
  PostApiV1UserSystemSettingListResponse[200];
/**
 * @description
 *   获取系统配置列表
 * @tags 用户端-系统配置管理
 * @x-apifox-folder "用户端-系统配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserSystemSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/system/setting/list";
  function request(): Promise<PostApiV1UserSystemSettingListResponseSuccess> {
    return requester(url, {
      method,
    }) as unknown as Promise<PostApiV1UserSystemSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminRoleModify */
export interface PostApiV1AdminAdminRoleModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminRoleModifyRequest;
}

/** @description response type for postApiV1AdminAdminRoleModify */
export interface PostApiV1AdminAdminRoleModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminRoleModifyResponseSuccess =
  PostApiV1AdminAdminRoleModifyResponse[200];
/**
 * @description
 *   更新角色
 * @tags 总管理后台-角色管理
 * @x-apifox-folder "总管理后台-角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminRoleModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/role/modify";
  function request(
    option?: PostApiV1AdminAdminRoleModifyOption
  ): Promise<PostApiV1AdminAdminRoleModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminRoleModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageHandle */
export interface PostApiV1ApiProgramStageHandleOption {
  body?: {
    /**
        @description
          阶段ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramStageHandle */
export interface PostApiV1ApiProgramStageHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageHandleResponseSuccess =
  PostApiV1ApiProgramStageHandleResponse[200];
/**
 * @description
 *   操作项目
 *   操作项目
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/handle";
  function request(
    option?: PostApiV1ApiProgramStageHandleOption
  ): Promise<PostApiV1ApiProgramStageHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminSystemSettingList */
export interface PostApiV1AdminSystemSettingListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: SystemSettingListRequest;
}

/** @description response type for postApiV1AdminSystemSettingList */
export interface PostApiV1AdminSystemSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: SystemSettingListResponse;
  };
}

export type PostApiV1AdminSystemSettingListResponseSuccess =
  PostApiV1AdminSystemSettingListResponse[200];
/**
 * @description
 *   获取系统配置列表
 * @tags 总管理后台-系统配置管理
 * @x-apifox-folder "总管理后台-系统配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminSystemSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/system/setting/list";
  function request(
    option?: PostApiV1AdminSystemSettingListOption
  ): Promise<PostApiV1AdminSystemSettingListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminSystemSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentAccountModify */
export interface PostApiV1AgentAgentAccountModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountModifyRequest;
}

/** @description response type for postApiV1AgentAgentAccountModify */
export interface PostApiV1AgentAgentAccountModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentAccountModifyResponseSuccess =
  PostApiV1AgentAgentAccountModifyResponse[200];
/**
 * @description
 *   更新代理账号
 * @tags 代理端-代理账号管理
 * @x-apifox-folder "代理端-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentAccountModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/account/modify";
  function request(
    option?: PostApiV1AgentAgentAccountModifyOption
  ): Promise<PostApiV1AgentAgentAccountModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentAccountModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminGameList */
export interface PostApiV1AdminGameListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: GameListRequest;
}

/** @description response type for postApiV1AdminGameList */
export interface PostApiV1AdminGameListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: GameListResponse;
  };
}

export type PostApiV1AdminGameListResponseSuccess =
  PostApiV1AdminGameListResponse[200];
/**
 * @description
 *   获取游戏列表
 * @tags 总管理后台-游戏管理
 * @x-apifox-folder "总管理后台-游戏管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminGameList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/game/list";
  function request(
    option?: PostApiV1AdminGameListOption
  ): Promise<PostApiV1AdminGameListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminGameListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentSettingGameList */
export interface PostApiV1AdminAgentSettingGameListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentGameListRequest;
}

/** @description response type for postApiV1AdminAgentSettingGameList */
export interface PostApiV1AdminAgentSettingGameListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: GameListResponse;
  };
}

export type PostApiV1AdminAgentSettingGameListResponseSuccess =
  PostApiV1AdminAgentSettingGameListResponse[200];
/**
 * @description
 *   获取游戏列表
 * @tags 总管理后台-代理配置管理
 * @x-apifox-folder "总管理后台-代理配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentSettingGameList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/setting/game/list";
  function request(
    option?: PostApiV1AdminAgentSettingGameListOption
  ): Promise<PostApiV1AdminAgentSettingGameListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentSettingGameListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiStageTaskList */
export interface PostApiV1ApiStageTaskListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询任务信息 */
      with_task?: number;
    };
    search?: {
      /**
        @description
          搜索条件.阶段ID */
      stage_id?: string;
      /**
        @description
          搜索条件.任务类型 */
      task_type?: number;
      /**
        @description
          搜索条件.任务ID */
      parent_task_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiStageTaskList */
export interface PostApiV1ApiStageTaskListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiStageTaskListResponseSuccess =
  PostApiV1ApiStageTaskListResponse[200];
/**
 * @description
 *   获取阶段任务列表
 *   获取阶段任务列表
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiStageTaskList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/stage/task/list";
  function request(
    option?: PostApiV1ApiStageTaskListOption
  ): Promise<PostApiV1ApiStageTaskListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiStageTaskListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentProfilePasswordChange */
export interface PostApiV1AgentProfilePasswordChangeOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: ProfileChangePasswordRequest;
}

/** @description response type for postApiV1AgentProfilePasswordChange */
export interface PostApiV1AgentProfilePasswordChangeResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentProfilePasswordChangeResponseSuccess =
  PostApiV1AgentProfilePasswordChangeResponse[200];
/**
 * @description
 *   修改当前账号登录密码
 * @tags 代理端-Profile
 * @x-apifox-folder "代理端-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AgentProfilePasswordChange = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/profile/password/change";
  function request(
    option?: PostApiV1AgentProfilePasswordChangeOption
  ): Promise<PostApiV1AgentProfilePasswordChangeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentProfilePasswordChangeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminGameModify */
export interface PostApiV1AdminGameModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: GameModifyRequest;
}

/** @description response type for postApiV1AdminGameModify */
export interface PostApiV1AdminGameModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminGameModifyResponseSuccess =
  PostApiV1AdminGameModifyResponse[200];
/**
 * @description
 *   更新游戏
 * @tags 总管理后台-游戏管理
 * @x-apifox-folder "总管理后台-游戏管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminGameModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/game/modify";
  function request(
    option?: PostApiV1AdminGameModifyOption
  ): Promise<PostApiV1AdminGameModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminGameModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskEmployeeRemove */
export interface PostApiV1ApiTaskEmployeeRemoveOption {
  body?: {
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          项目ID */
      program_id?: string;
      /**
        @description
          员工ID
        @default
        @example
           */
      employee_list?: Array<string>;
    };
  };
}

/** @description response type for postApiV1ApiTaskEmployeeRemove */
export interface PostApiV1ApiTaskEmployeeRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskEmployeeRemoveResponseSuccess =
  PostApiV1ApiTaskEmployeeRemoveResponse[200];
/**
 * @description
 *   删除任务成员
 *   删除任务成员
 * @tags 客户端接口.任务成员
 * @x-apifox-folder "客户端接口.任务成员"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskEmployeeRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/employee/remove";
  function request(
    option?: PostApiV1ApiTaskEmployeeRemoveOption
  ): Promise<PostApiV1ApiTaskEmployeeRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskEmployeeRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserOrderDetail */
export interface PostApiV1UserOrderDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderDetailRequest;
}

/** @description response type for postApiV1UserOrderDetail */
export interface PostApiV1UserOrderDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderDetailResponse;
  };
}

export type PostApiV1UserOrderDetailResponseSuccess =
  PostApiV1UserOrderDetailResponse[200];
/**
 * @description
 *   获取订单详情
 * @tags 用户端-订单管理
 * @x-apifox-folder "用户端-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserOrderDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/order/detail";
  function request(
    option?: PostApiV1UserOrderDetailOption
  ): Promise<PostApiV1UserOrderDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserOrderDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentCreate */
export interface PostApiV1AdminAgentCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentCreateRequest;
}

/** @description response type for postApiV1AdminAgentCreate */
export interface PostApiV1AdminAgentCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAgentCreateResponseSuccess =
  PostApiV1AdminAgentCreateResponse[200];
/**
 * @description
 *   创建代理
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/create";
  function request(
    option?: PostApiV1AdminAgentCreateOption
  ): Promise<PostApiV1AdminAgentCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1TestOrderMake */
export interface PostApiV1TestOrderMakeOption {
  body?: OrderMakeRequest;
}

/** @description response type for postApiV1TestOrderMake */
export interface PostApiV1TestOrderMakeResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1TestOrderMakeResponseSuccess =
  PostApiV1TestOrderMakeResponse[200];
/**
 * @description
 *   下单
 * @tags 测试接口-订单管理
 * @x-apifox-folder "测试接口-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1TestOrderMake = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/test/order/make";
  function request(
    option?: PostApiV1TestOrderMakeOption
  ): Promise<PostApiV1TestOrderMakeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1TestOrderMakeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserStatsDailySnapshotDetail */
export interface PostApiV1UserUserStatsDailySnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsDailySnapshotDetailRequest;
}

/** @description response type for postApiV1UserUserStatsDailySnapshotDetail */
export interface PostApiV1UserUserStatsDailySnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsDailySnapshotDetailResponse;
  };
}

export type PostApiV1UserUserStatsDailySnapshotDetailResponseSuccess =
  PostApiV1UserUserStatsDailySnapshotDetailResponse[200];
/**
 * @description
 *   获取汇总统计详情
 * @tags 用户端-汇总统计管理
 * @x-apifox-folder "用户端-汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserStatsDailySnapshotDetail =
  /* #__PURE__ */ (() => {
    const method = "post";
    const url = "/api/v1/user/user/stats/daily/snapshot/detail";
    function request(
      option?: PostApiV1UserUserStatsDailySnapshotDetailOption
    ): Promise<PostApiV1UserUserStatsDailySnapshotDetailResponseSuccess> {
      return requester(url, {
        method,
        ...option,
      }) as unknown as Promise<PostApiV1UserUserStatsDailySnapshotDetailResponseSuccess>;
    }

    /** http method */
    request.method = method;
    /** request url */
    request.url = url;
    return request;
  })();

/** @description request parameter type for postApiV1ApiProfilePasswordChange */
export interface PostApiV1ApiProfilePasswordChangeOption {
  body?: {
    /**
        @description
          旧密码 */
    password: string;
    /**
        @description
          新密码 */
    new_password: string;
  };
}

/** @description response type for postApiV1ApiProfilePasswordChange */
export interface PostApiV1ApiProfilePasswordChangeResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProfilePasswordChangeResponseSuccess =
  PostApiV1ApiProfilePasswordChangeResponse[200];
/**
 * @description
 *   修改密码
 *   修改密码
 * @tags 客户端接口.个人账户资料
 * @x-apifox-folder "客户端接口.个人账户资料"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProfilePasswordChange = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/profile/password/change";
  function request(
    option?: PostApiV1ApiProfilePasswordChangeOption
  ): Promise<PostApiV1ApiProfilePasswordChangeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProfilePasswordChangeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminOrderDetail */
export interface PostApiV1AdminOrderDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderDetailRequest;
}

/** @description response type for postApiV1AdminOrderDetail */
export interface PostApiV1AdminOrderDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderDetailResponse;
  };
}

export type PostApiV1AdminOrderDetailResponseSuccess =
  PostApiV1AdminOrderDetailResponse[200];
/**
 * @description
 *   获取订单详情
 * @tags 总管理后台-订单管理
 * @x-apifox-folder "总管理后台-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminOrderDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/order/detail";
  function request(
    option?: PostApiV1AdminOrderDetailOption
  ): Promise<PostApiV1AdminOrderDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminOrderDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramHandle */
export interface PostApiV1ApiProgramHandleOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramHandle */
export interface PostApiV1ApiProgramHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramHandleResponseSuccess =
  PostApiV1ApiProgramHandleResponse[200];
/**
 * @description
 *   操作项目
 *   操作项目
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/handle";
  function request(
    option?: PostApiV1ApiProgramHandleOption
  ): Promise<PostApiV1ApiProgramHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserStatsDailySnapshotList */
export interface PostApiV1AdminUserStatsDailySnapshotListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsDailySnapshotListRequest;
}

/** @description response type for postApiV1AdminUserStatsDailySnapshotList */
export interface PostApiV1AdminUserStatsDailySnapshotListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsDailySnapshotListResponse;
  };
}

export type PostApiV1AdminUserStatsDailySnapshotListResponseSuccess =
  PostApiV1AdminUserStatsDailySnapshotListResponse[200];
/**
 * @description
 *   获取汇总统计列表
 * @tags 总管理后台-用户汇总统计管理
 * @x-apifox-folder "总管理后台-用户汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserStatsDailySnapshotList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/stats/daily/snapshot/list";
  function request(
    option?: PostApiV1AdminUserStatsDailySnapshotListOption
  ): Promise<PostApiV1AdminUserStatsDailySnapshotListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserStatsDailySnapshotListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiIndexLogin */
export interface PostApiV1ApiIndexLoginOption {
  body?: {
    /**
        @description
          手机号 */
    mobile: string;
    /**
        @description
          密码 */
    password: string;
  };
}

/** @description response type for postApiV1ApiIndexLogin */
export interface PostApiV1ApiIndexLoginResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiIndexLoginResponseSuccess =
  PostApiV1ApiIndexLoginResponse[200];
/**
 * @description
 *   用户登录
 *   登录
 * @tags 客户端接口.登录
 * @x-apifox-folder "客户端接口.登录"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiIndexLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/index/login";
  function request(
    option?: PostApiV1ApiIndexLoginOption
  ): Promise<PostApiV1ApiIndexLoginResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiIndexLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserBalanceRecordList */
export interface PostApiV1AdminUserBalanceRecordListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserBalanceRecordListRequest;
}

/** @description response type for postApiV1AdminUserBalanceRecordList */
export interface PostApiV1AdminUserBalanceRecordListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserBalanceRecordListResponse;
  };
}

export type PostApiV1AdminUserBalanceRecordListResponseSuccess =
  PostApiV1AdminUserBalanceRecordListResponse[200];
/**
 * @description
 *   获取用户资产记录列表
 * @tags 总管理后台-用户资产记录管理
 * @x-apifox-folder "总管理后台-用户资产记录管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserBalanceRecordList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/userBalanceRecord/list";
  function request(
    option?: PostApiV1AdminUserBalanceRecordListOption
  ): Promise<PostApiV1AdminUserBalanceRecordListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserBalanceRecordListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageList */
export interface PostApiV1ApiProgramStageListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.周期 */
      with_cycles?: number;
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目ID */
      program_id?: string;
      /**
        @description
          搜索条件.任务ID */
      task_id?: string;
      /**
        @description
          搜索条件.阶段状态 */
      status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramStageList */
export interface PostApiV1ApiProgramStageListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageListResponseSuccess =
  PostApiV1ApiProgramStageListResponse[200];
/**
 * @description
 *   获取阶段列表
 *   获取阶段列表
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/list";
  function request(
    option?: PostApiV1ApiProgramStageListOption
  ): Promise<PostApiV1ApiProgramStageListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiSummaryList */
export interface PostApiV1ApiSummaryListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: string;
    };
    search?: {
      /**
        @description
          搜索条件.总结状态 */
      status?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: string;
      /**
        @description
          分页参数.每页个数 */
      page_size?: string;
      /**
        @description
          分页参数.是否返回总数 */
      total?: string;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: string;
    };
  };
}

/** @description response type for postApiV1ApiSummaryList */
export interface PostApiV1ApiSummaryListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiSummaryListResponseSuccess =
  PostApiV1ApiSummaryListResponse[200];
/**
 * @description
 *   获取总结列表
 *   获取总结列表
 * @tags 客户端接口.总结
 * @x-apifox-folder "客户端接口.总结"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiSummaryList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/summary/list";
  function request(
    option?: PostApiV1ApiSummaryListOption
  ): Promise<PostApiV1ApiSummaryListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiSummaryListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreSettingList */
export interface PostApiV1AdminStoreSettingListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreSettingListRequest;
}

/** @description response type for postApiV1AdminStoreSettingList */
export interface PostApiV1AdminStoreSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StoreSettingListResponse;
  };
}

export type PostApiV1AdminStoreSettingListResponseSuccess =
  PostApiV1AdminStoreSettingListResponse[200];
/**
 * @description
 *   获取站点配置列表
 * @tags 总管理后台-站点配置管理
 * @x-apifox-folder "总管理后台-站点配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/setting/list";
  function request(
    option?: PostApiV1AdminStoreSettingListOption
  ): Promise<PostApiV1AdminStoreSettingListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentProfileDetail */
export interface PostApiV1AgentProfileDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
}

/** @description response type for postApiV1AgentProfileDetail */
export interface PostApiV1AgentProfileDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentAccountDetailResponse;
  };
}

export type PostApiV1AgentProfileDetailResponseSuccess =
  PostApiV1AgentProfileDetailResponse[200];
/**
 * @description
 *   获取当前登录账号详情
 * @tags 代理端-Profile
 * @x-apifox-folder "代理端-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AgentProfileDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/profile/detail";
  function request(
    option?: PostApiV1AgentProfileDetailOption
  ): Promise<PostApiV1AgentProfileDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentProfileDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountList */
export interface PostApiV1AdminAdminAccountListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountListRequest;
}

/** @description response type for postApiV1AdminAdminAccountList */
export interface PostApiV1AdminAdminAccountListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminAccountListResponse;
  };
}

export type PostApiV1AdminAdminAccountListResponseSuccess =
  PostApiV1AdminAdminAccountListResponse[200];
/**
 * @description
 *   获取管理账号列表
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/list";
  function request(
    option?: PostApiV1AdminAdminAccountListOption
  ): Promise<PostApiV1AdminAdminAccountListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramEmployeeList */
export interface PostApiV1ApiProgramEmployeeListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询员工信息 */
      with_employee?: number;
      /**
        @description
          关联条件.查询角色信息 */
      with_role?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目ID */
      program_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramEmployeeList */
export interface PostApiV1ApiProgramEmployeeListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramEmployeeListResponseSuccess =
  PostApiV1ApiProgramEmployeeListResponse[200];
/**
 * @description
 *   获取项目成员管理列表
 *   获取项目成员管理列表
 * @tags 客户端接口.项目成员管理
 * @x-apifox-folder "客户端接口.项目成员管理"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramEmployeeList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/employee/list";
  function request(
    option?: PostApiV1ApiProgramEmployeeListOption
  ): Promise<PostApiV1ApiProgramEmployeeListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramEmployeeListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentAccountList */
export interface PostApiV1AgentAgentAccountListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountListRequest;
}

/** @description response type for postApiV1AgentAgentAccountList */
export interface PostApiV1AgentAgentAccountListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentAccountListResponse;
  };
}

export type PostApiV1AgentAgentAccountListResponseSuccess =
  PostApiV1AgentAgentAccountListResponse[200];
/**
 * @description
 *   获取代理账号列表
 * @tags 代理端-代理账号管理
 * @x-apifox-folder "代理端-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentAccountList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/account/list";
  function request(
    option?: PostApiV1AgentAgentAccountListOption
  ): Promise<PostApiV1AgentAgentAccountListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentAccountListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentSettingModify */
export interface PostApiV1AdminAgentSettingModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentSettingModifyRequest;
}

/** @description response type for postApiV1AdminAgentSettingModify */
export interface PostApiV1AdminAgentSettingModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAgentSettingModifyResponseSuccess =
  PostApiV1AdminAgentSettingModifyResponse[200];
/**
 * @description
 *   更新代理配置
 * @tags 总管理后台-代理配置管理
 * @x-apifox-folder "总管理后台-代理配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentSettingModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/setting/modify";
  function request(
    option?: PostApiV1AdminAgentSettingModifyOption
  ): Promise<PostApiV1AdminAgentSettingModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentSettingModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountReset */
export interface PostApiV1AdminAdminAccountResetOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountResetRequest;
}

/** @description response type for postApiV1AdminAdminAccountReset */
export interface PostApiV1AdminAdminAccountResetResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminAccountResetResponseSuccess =
  PostApiV1AdminAdminAccountResetResponse[200];
/**
 * @description
 *   重置管理账号
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountReset = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/reset";
  function request(
    option?: PostApiV1AdminAdminAccountResetOption
  ): Promise<PostApiV1AdminAdminAccountResetResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountResetResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTrashResultList */
export interface PostApiV1ApiTrashResultListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.任务 */
      with_task?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目ID */
      program_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTrashResultList */
export interface PostApiV1ApiTrashResultListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTrashResultListResponseSuccess =
  PostApiV1ApiTrashResultListResponse[200];
/**
 * @description
 *   获取成果列表
 *   获取成果列表
 * @tags 客户端接口.回收站
 * @x-apifox-folder "客户端接口.回收站"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTrashResultList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/trash/result/list";
  function request(
    option?: PostApiV1ApiTrashResultListOption
  ): Promise<PostApiV1ApiTrashResultListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTrashResultListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentModify */
export interface PostApiV1AgentAgentModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentModifyRequest;
}

/** @description response type for postApiV1AgentAgentModify */
export interface PostApiV1AgentAgentModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentModifyResponseSuccess =
  PostApiV1AgentAgentModifyResponse[200];
/**
 * @description
 *   更新代理
 * @tags 代理端-子代理管理
 * @x-apifox-folder "代理端-子代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/modify";
  function request(
    option?: PostApiV1AgentAgentModifyOption
  ): Promise<PostApiV1AgentAgentModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserRegister */
export interface PostApiV1UserRegisterOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserRegisterRequest;
}

/** @description response type for postApiV1UserRegister */
export interface PostApiV1UserRegisterResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserLoginResponse;
  };
}

export type PostApiV1UserRegisterResponseSuccess =
  PostApiV1UserRegisterResponse[200];
/**
 * @description
 *   注册
 * @tags 用户端-用户管理
 * @x-apifox-folder "用户端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserRegister = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/register";
  function request(
    option?: PostApiV1UserRegisterOption
  ): Promise<PostApiV1UserRegisterResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserRegisterResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryRemove */
export interface PostApiV1ApiTaskSummaryRemoveOption {
  body?: {
    /**
        @description
          任务总结ID */
    summary_id: string;
    /**
        @description
          任务ID */
    task_id: string;
  };
}

/** @description response type for postApiV1ApiTaskSummaryRemove */
export interface PostApiV1ApiTaskSummaryRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryRemoveResponseSuccess =
  PostApiV1ApiTaskSummaryRemoveResponse[200];
/**
 * @description
 *   删除任务总结报告
 *   删除任务总结报告
 * @tags 客户端接口.任务总结报告
 * @x-apifox-folder "客户端接口.任务总结报告"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/remove";
  function request(
    option?: PostApiV1ApiTaskSummaryRemoveOption
  ): Promise<PostApiV1ApiTaskSummaryRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreStatsSnapshotList */
export interface PostApiV1AdminStoreStatsSnapshotListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AdminStoreStatsSnapshotList */
export interface PostApiV1AdminStoreStatsSnapshotListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StoreStatsSnapshotDetailResponse;
  };
}

export type PostApiV1AdminStoreStatsSnapshotListResponseSuccess =
  PostApiV1AdminStoreStatsSnapshotListResponse[200];
/**
 * @description
 *   获取站点统计列表
 * @tags 总管理后台-站点统计管理
 * @x-apifox-folder "总管理后台-站点统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreStatsSnapshotList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/stats/snapshot/list";
  function request(
    option?: PostApiV1AdminStoreStatsSnapshotListOption
  ): Promise<PostApiV1AdminStoreStatsSnapshotListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreStatsSnapshotListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryDetail */
export interface PostApiV1ApiTaskSummaryDetailOption {
  body?: {
    /**
        @description
          任务成果ID */
    result_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskSummaryDetail */
export interface PostApiV1ApiTaskSummaryDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryDetailResponseSuccess =
  PostApiV1ApiTaskSummaryDetailResponse[200];
/**
 * @description
 *   获取任务成果详情
 *   获取任务成果详情
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/detail";
  function request(
    option?: PostApiV1ApiTaskSummaryDetailOption
  ): Promise<PostApiV1ApiTaskSummaryDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiSummaryCreate */
export interface PostApiV1ApiSummaryCreateOption {
  body?: {
    data?: {
      status?: string;
    };
  };
}

/** @description response type for postApiV1ApiSummaryCreate */
export interface PostApiV1ApiSummaryCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiSummaryCreateResponseSuccess =
  PostApiV1ApiSummaryCreateResponse[200];
/**
 * @description
 *   创建总结
 *   创建总结
 * @tags 客户端接口.总结
 * @x-apifox-folder "客户端接口.总结"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiSummaryCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/summary/create";
  function request(
    option?: PostApiV1ApiSummaryCreateOption
  ): Promise<PostApiV1ApiSummaryCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiSummaryCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentSettingList */
export interface PostApiV1AdminAgentSettingListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentSettingListRequest;
}

/** @description response type for postApiV1AdminAgentSettingList */
export interface PostApiV1AdminAgentSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentSettingListResponse;
  };
}

export type PostApiV1AdminAgentSettingListResponseSuccess =
  PostApiV1AdminAgentSettingListResponse[200];
/**
 * @description
 *   获取代理配置列表
 * @tags 总管理后台-代理配置管理
 * @x-apifox-folder "总管理后台-代理配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/setting/list";
  function request(
    option?: PostApiV1AdminAgentSettingListOption
  ): Promise<PostApiV1AdminAgentSettingListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminRoleRemove */
export interface PostApiV1AdminAdminRoleRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminRoleRemoveRequest;
}

/** @description response type for postApiV1AdminAdminRoleRemove */
export interface PostApiV1AdminAdminRoleRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminRoleRemoveResponseSuccess =
  PostApiV1AdminAdminRoleRemoveResponse[200];
/**
 * @description
 *   删除角色
 * @tags 总管理后台-角色管理
 * @x-apifox-folder "总管理后台-角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminRoleRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/role/remove";
  function request(
    option?: PostApiV1AdminAdminRoleRemoveOption
  ): Promise<PostApiV1AdminAdminRoleRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminRoleRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminBlacklistCreate */
export interface PostApiV1AdminBlacklistCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: BlacklistCreateRequest;
}

/** @description response type for postApiV1AdminBlacklistCreate */
export interface PostApiV1AdminBlacklistCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminBlacklistCreateResponseSuccess =
  PostApiV1AdminBlacklistCreateResponse[200];
/**
 * @description
 *   创建黑名单
 * @tags 总管理后台-黑名单管理
 * @x-apifox-folder "总管理后台-黑名单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminBlacklistCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/blacklist/create";
  function request(
    option?: PostApiV1AdminBlacklistCreateOption
  ): Promise<PostApiV1AdminBlacklistCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminBlacklistCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminPermissionList */
export interface PostApiV1AdminAdminPermissionListOption {
  body?: AdminPermissionListRequest;
}

/** @description response type for postApiV1AdminAdminPermissionList */
export interface PostApiV1AdminAdminPermissionListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminPermissionListResponse;
  };
}

export type PostApiV1AdminAdminPermissionListResponseSuccess =
  PostApiV1AdminAdminPermissionListResponse[200];
/**
 * @description
 *   获取权限列表
 * @tags 总管理后台-权限管理
 * @x-apifox-folder "总管理后台-权限管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminPermissionList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/permission/list";
  function request(
    option?: PostApiV1AdminAdminPermissionListOption
  ): Promise<PostApiV1AdminAdminPermissionListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminPermissionListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskCreate */
export interface PostApiV1ApiTaskCreateOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          任务类型 */
      type?: number;
      /**
        @description
          是否子级模块 */
      is_sub_module?: number;
      /**
        @description
          标题 */
      task_title?: string;
      /**
        @description
          上级ID */
      parent_task_id?: string;
      /**
        @description
          负责人ID */
      director_id?: string;
      /**
        @description
          任务描述 */
      description?: string;
      /**
        @description
          进度类型 */
      progress_type?: number;
      employee_list?: Array<{
        /**
        @description
          用户ID */
        employee_id?: string;
        /**
        @description
          是否只读 */
        is_readonly?: number;
      }>;
    };
  };
}

/** @description response type for postApiV1ApiTaskCreate */
export interface PostApiV1ApiTaskCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskCreateResponseSuccess =
  PostApiV1ApiTaskCreateResponse[200];
/**
 * @description
 *   创建任务
 *   创建任务
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/create";
  function request(
    option?: PostApiV1ApiTaskCreateOption
  ): Promise<PostApiV1ApiTaskCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1TestStatsAgentStats */
export interface PostApiV1TestStatsAgentStatsOption {
  body?: GenerateAgentStatsRequest;
}

/** @description response type for postApiV1TestStatsAgentStats */
export interface PostApiV1TestStatsAgentStatsResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1TestStatsAgentStatsResponseSuccess =
  PostApiV1TestStatsAgentStatsResponse[200];
/**
 * @description
 *   生成代理统计
 * @tags 测试接口-统计管理
 * @x-apifox-folder "测试接口-统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1TestStatsAgentStats = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/test/stats/agent/stats";
  function request(
    option?: PostApiV1TestStatsAgentStatsOption
  ): Promise<PostApiV1TestStatsAgentStatsResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1TestStatsAgentStatsResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageRemove */
export interface PostApiV1ApiProgramStageRemoveOption {
  body?: {
    /**
        @description
          项目ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
  };
}

/** @description response type for postApiV1ApiProgramStageRemove */
export interface PostApiV1ApiProgramStageRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageRemoveResponseSuccess =
  PostApiV1ApiProgramStageRemoveResponse[200];
/**
 * @description
 *   删除阶段
 *   删除阶段
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/remove";
  function request(
    option?: PostApiV1ApiProgramStageRemoveOption
  ): Promise<PostApiV1ApiProgramStageRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountModify */
export interface PostApiV1AdminAdminAccountModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountModifyRequest;
}

/** @description response type for postApiV1AdminAdminAccountModify */
export interface PostApiV1AdminAdminAccountModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminAccountModifyResponseSuccess =
  PostApiV1AdminAdminAccountModifyResponse[200];
/**
 * @description
 *   更新管理账号
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/modify";
  function request(
    option?: PostApiV1AdminAdminAccountModifyOption
  ): Promise<PostApiV1AdminAdminAccountModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageModify */
export interface PostApiV1ApiProgramStageModifyOption {
  body?: {
    /**
        @description
          阶段ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          阶段名称 */
      stage_name?: string;
      /**
        @description
          进度类型 */
      progress_type?: number;
      /**
        @description
          基础值 */
      progress_base?: number;
      /**
        @description
          完成值 */
      progress?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramStageModify */
export interface PostApiV1ApiProgramStageModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageModifyResponseSuccess =
  PostApiV1ApiProgramStageModifyResponse[200];
/**
 * @description
 *   编辑阶段
 *   编辑阶段
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/modify";
  function request(
    option?: PostApiV1ApiProgramStageModifyOption
  ): Promise<PostApiV1ApiProgramStageModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStatsSnapshotBalance */
export interface PostApiV1AdminStatsSnapshotBalanceOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
}

/** @description response type for postApiV1AdminStatsSnapshotBalance */
export interface PostApiV1AdminStatsSnapshotBalanceResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: WalletBalanceResponse;
  };
}

export type PostApiV1AdminStatsSnapshotBalanceResponseSuccess =
  PostApiV1AdminStatsSnapshotBalanceResponse[200];
/**
 * @description
 *   获取手续费以及派奖地址余额
 * @tags 总管理后台-统计管理
 * @x-apifox-folder "总管理后台-统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStatsSnapshotBalance = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/stats/snapshot/balance";
  function request(
    option?: PostApiV1AdminStatsSnapshotBalanceOption
  ): Promise<PostApiV1AdminStatsSnapshotBalanceResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStatsSnapshotBalanceResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTrashStageList */
export interface PostApiV1ApiTrashStageListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.周期 */
      with_program?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目ID */
      program_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTrashStageList */
export interface PostApiV1ApiTrashStageListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTrashStageListResponseSuccess =
  PostApiV1ApiTrashStageListResponse[200];
/**
 * @description
 *   获取阶段列表
 *   获取阶段列表
 * @tags 客户端接口.回收站
 * @x-apifox-folder "客户端接口.回收站"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTrashStageList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/trash/stage/list";
  function request(
    option?: PostApiV1ApiTrashStageListOption
  ): Promise<PostApiV1ApiTrashStageListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTrashStageListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryList */
export interface PostApiV1ApiTaskSummaryListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询发布人信息 */
      with_employee?: number;
      /**
        @description
          关联条件.查询周期信息 */
      with_cycle?: number;
      /**
        @description
          关联条件.查询阶段信息 */
      with_stage?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务ID */
      task_id?: string;
      /**
        @description
          搜索条件.阶段ID */
      stage_id?: string;
      /**
        @description
          搜索条件.任务总结报告状态 */
      status?: number;
      /**
        @description
          搜索条件.任务总结报告类型 */
      type?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskSummaryList */
export interface PostApiV1ApiTaskSummaryListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryListResponseSuccess =
  PostApiV1ApiTaskSummaryListResponse[200];
/**
 * @description
 *   获取任务报告列表
 *   获取任务总结报告列表
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/list";
  function request(
    option?: PostApiV1ApiTaskSummaryListOption
  ): Promise<PostApiV1ApiTaskSummaryListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentStatsDailySnapshotDetail */
export interface PostApiV1AgentAgentStatsDailySnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentStatsDailySnapshotDetailRequest;
}

/** @description response type for postApiV1AgentAgentStatsDailySnapshotDetail */
export interface PostApiV1AgentAgentStatsDailySnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentStatsDailySnapshotDetailResponse;
  };
}

export type PostApiV1AgentAgentStatsDailySnapshotDetailResponseSuccess =
  PostApiV1AgentAgentStatsDailySnapshotDetailResponse[200];
/**
 * @description
 *   获取汇总统计详情
 * @tags 代理端-汇总统计管理
 * @x-apifox-folder "代理端-汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentStatsDailySnapshotDetail =
  /* #__PURE__ */ (() => {
    const method = "post";
    const url = "/api/v1/agent/agent/stats/daily/snapshot/detail";
    function request(
      option?: PostApiV1AgentAgentStatsDailySnapshotDetailOption
    ): Promise<PostApiV1AgentAgentStatsDailySnapshotDetailResponseSuccess> {
      return requester(url, {
        method,
        ...option,
      }) as unknown as Promise<PostApiV1AgentAgentStatsDailySnapshotDetailResponseSuccess>;
    }

    /** http method */
    request.method = method;
    /** request url */
    request.url = url;
    return request;
  })();

/** @description request parameter type for postApiV1AgentAgentRelationDetail */
export interface PostApiV1AgentAgentRelationDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRelationDetailRequest;
}

/** @description response type for postApiV1AgentAgentRelationDetail */
export interface PostApiV1AgentAgentRelationDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentRelationDetailResponse;
  };
}

export type PostApiV1AgentAgentRelationDetailResponseSuccess =
  PostApiV1AgentAgentRelationDetailResponse[200];
/**
 * @description
 *   获取代理关系详情
 * @tags 代理端-代理关系管理
 * @x-apifox-folder "代理端-代理关系管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRelationDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/relation/detail";
  function request(
    option?: PostApiV1AgentAgentRelationDetailOption
  ): Promise<PostApiV1AgentAgentRelationDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRelationDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleRemove */
export interface PostApiV1ApiProgramCycleRemoveOption {
  body?: {
    /**
        @description
          周期ID */
    cycle_id: string;
    /**
        @description
          阶段ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
  };
}

/** @description response type for postApiV1ApiProgramCycleRemove */
export interface PostApiV1ApiProgramCycleRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleRemoveResponseSuccess =
  PostApiV1ApiProgramCycleRemoveResponse[200];
/**
 * @description
 *   归档周期
 *   归档周期
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/remove";
  function request(
    option?: PostApiV1ApiProgramCycleRemoveOption
  ): Promise<PostApiV1ApiProgramCycleRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramModify */
export interface PostApiV1ApiProgramModifyOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          项目名称 */
      program_name?: string;
      /**
        @description
          项目标识 */
      label?: string;
      /**
        @description
          项目描述 */
      description?: string;
    };
  };
}

/** @description response type for postApiV1ApiProgramModify */
export interface PostApiV1ApiProgramModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramModifyResponseSuccess =
  PostApiV1ApiProgramModifyResponse[200];
/**
 * @description
 *   编辑项目
 *   编辑项目
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/modify";
  function request(
    option?: PostApiV1ApiProgramModifyOption
  ): Promise<PostApiV1ApiProgramModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserRelationList */
export interface PostApiV1UserUserRelationListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserRelationListRequest;
}

/** @description response type for postApiV1UserUserRelationList */
export interface PostApiV1UserUserRelationListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserRelationListResponse;
  };
}

export type PostApiV1UserUserRelationListResponseSuccess =
  PostApiV1UserUserRelationListResponse[200];
/**
 * @description
 *   获取用户下级列表
 * @tags 用户端-用户关系管理
 * @x-apifox-folder "用户端-用户关系管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserRelationList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/user/relation/list";
  function request(
    option?: PostApiV1UserUserRelationListOption
  ): Promise<PostApiV1UserUserRelationListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserUserRelationListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentCoinList */
export interface PostApiV1AgentCoinListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: CoinListRequest;
}

/** @description response type for postApiV1AgentCoinList */
export interface PostApiV1AgentCoinListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: CoinListResponse;
  };
}

export type PostApiV1AgentCoinListResponseSuccess =
  PostApiV1AgentCoinListResponse[200];
/**
 * @description
 *   获取币种列表
 * @tags 代理端-币种管理
 * @x-apifox-folder "代理端-币种管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentCoinList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/coin/list";
  function request(
    option?: PostApiV1AgentCoinListOption
  ): Promise<PostApiV1AgentCoinListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentCoinListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramList */
export interface PostApiV1ApiProgramListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目状态 */
      status?: number;
      /**
        @description
          搜索条件.项目名称模糊搜索 */
      program_name?: string;
      /**
        @description
          搜索条件.最近访问 */
      recent_visit?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramList */
export interface PostApiV1ApiProgramListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramListResponseSuccess =
  PostApiV1ApiProgramListResponse[200];
/**
 * @description
 *   获取项目列表
 *   获取项目列表
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/list";
  function request(
    option?: PostApiV1ApiProgramListOption
  ): Promise<PostApiV1ApiProgramListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminWithdrawOrderReject */
export interface PostApiV1AdminWithdrawOrderRejectOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: WithdrawOrderModifyRequest;
}

/** @description response type for postApiV1AdminWithdrawOrderReject */
export interface PostApiV1AdminWithdrawOrderRejectResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminWithdrawOrderRejectResponseSuccess =
  PostApiV1AdminWithdrawOrderRejectResponse[200];
/**
 * @description
 *   拒绝提现申请
 * @tags 总管理后台-提现申请管理
 * @x-apifox-folder "总管理后台-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminWithdrawOrderReject = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/withdrawOrder/reject";
  function request(
    option?: PostApiV1AdminWithdrawOrderRejectOption
  ): Promise<PostApiV1AdminWithdrawOrderRejectResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminWithdrawOrderRejectResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultDetail */
export interface PostApiV1ApiTaskResultDetailOption {
  body?: {
    /**
        @description
          任务成果ID */
    result_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskResultDetail */
export interface PostApiV1ApiTaskResultDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultDetailResponseSuccess =
  PostApiV1ApiTaskResultDetailResponse[200];
/**
 * @description
 *   获取任务成果详情
 *   获取任务成果详情
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/detail";
  function request(
    option?: PostApiV1ApiTaskResultDetailOption
  ): Promise<PostApiV1ApiTaskResultDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskLog */
export interface PostApiV1ApiTaskLogOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          任务ID */
    task_id: string;
  };
}

/** @description response type for postApiV1ApiTaskLog */
export interface PostApiV1ApiTaskLogResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskLogResponseSuccess =
  PostApiV1ApiTaskLogResponse[200];
/**
 * @description
 *   操作日志
 *   操作日志
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskLog = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/log";
  function request(
    option?: PostApiV1ApiTaskLogOption
  ): Promise<PostApiV1ApiTaskLogResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskLogResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskDetail */
export interface PostApiV1ApiTaskDetailOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          任务ID */
    task_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人 */
      with_director?: number;
      /**
        @description
          关联条件.查询模块信息 */
      with_module?: number;
      /**
        @description
          关联条件.查询阶段信息 */
      with_stages?: number;
      /**
        @description
          关联条件.查询周期信息 */
      with_cycles?: number;
      /**
        @description
          关联条件.查询员工信息 */
      with_employees?: number;
      /**
        @description
          关联条件.查询报告信息 */
      with_summary?: number;
      /**
        @description
          关联条件.查询成果信息 */
      with_results?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskDetail */
export interface PostApiV1ApiTaskDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskDetailResponseSuccess =
  PostApiV1ApiTaskDetailResponse[200];
/**
 * @description
 *   获取任务详情
 *   获取任务详情
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/detail";
  function request(
    option?: PostApiV1ApiTaskDetailOption
  ): Promise<PostApiV1ApiTaskDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentStatsSnapshotDetail */
export interface PostApiV1AdminAgentStatsSnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AdminAgentStatsSnapshotDetail */
export interface PostApiV1AdminAgentStatsSnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentStatsSnapshotDetailResponse;
  };
}

export type PostApiV1AdminAgentStatsSnapshotDetailResponseSuccess =
  PostApiV1AdminAgentStatsSnapshotDetailResponse[200];
/**
 * @description
 *   获取统计
 * @tags 总管理后台-代理统计管理
 * @x-apifox-folder "总管理后台-代理统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentStatsSnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/stats/snapshot/detail";
  function request(
    option?: PostApiV1AdminAgentStatsSnapshotDetailOption
  ): Promise<PostApiV1AdminAgentStatsSnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentStatsSnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreStatsDailySnapshotList */
export interface PostApiV1AdminStoreStatsDailySnapshotListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreStatsDailySnapshotListRequest;
}

/** @description response type for postApiV1AdminStoreStatsDailySnapshotList */
export interface PostApiV1AdminStoreStatsDailySnapshotListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StoreStatsDailySnapshotListResponse;
  };
}

export type PostApiV1AdminStoreStatsDailySnapshotListResponseSuccess =
  PostApiV1AdminStoreStatsDailySnapshotListResponse[200];
/**
 * @description
 *   获取站点汇总统计列表
 * @tags 总管理后台-站点汇总统计管理
 * @x-apifox-folder "总管理后台-站点汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreStatsDailySnapshotList =
  /* #__PURE__ */ (() => {
    const method = "post";
    const url = "/api/v1/admin/store/stats/daily/snapshot/list";
    function request(
      option?: PostApiV1AdminStoreStatsDailySnapshotListOption
    ): Promise<PostApiV1AdminStoreStatsDailySnapshotListResponseSuccess> {
      return requester(url, {
        method,
        ...option,
      }) as unknown as Promise<PostApiV1AdminStoreStatsDailySnapshotListResponseSuccess>;
    }

    /** http method */
    request.method = method;
    /** request url */
    request.url = url;
    return request;
  })();

/** @description request parameter type for postApiV1ApiTrashTaskList */
export interface PostApiV1ApiTrashTaskListOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    search?: {
      /**
        @description
          搜索条件.任务类型 */
      type?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTrashTaskList */
export interface PostApiV1ApiTrashTaskListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTrashTaskListResponseSuccess =
  PostApiV1ApiTrashTaskListResponse[200];
/**
 * @description
 *   获取任务列表
 *   获取任务列表
 * @tags 客户端接口.回收站
 * @x-apifox-folder "客户端接口.回收站"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTrashTaskList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/trash/task/list";
  function request(
    option?: PostApiV1ApiTrashTaskListOption
  ): Promise<PostApiV1ApiTrashTaskListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTrashTaskListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentAccountDetail */
export interface PostApiV1AdminAgentAccountDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountDetailRequest;
}

/** @description response type for postApiV1AdminAgentAccountDetail */
export interface PostApiV1AdminAgentAccountDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentAccountDetailResponse;
  };
}

export type PostApiV1AdminAgentAccountDetailResponseSuccess =
  PostApiV1AdminAgentAccountDetailResponse[200];
/**
 * @description
 *   获取代理账号详情
 * @tags 总管理后台-代理账号管理
 * @x-apifox-folder "总管理后台-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentAccountDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/account/detail";
  function request(
    option?: PostApiV1AdminAgentAccountDetailOption
  ): Promise<PostApiV1AdminAgentAccountDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentAccountDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRemove */
export interface PostApiV1AgentAgentRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRemoveRequest;
}

/** @description response type for postApiV1AgentAgentRemove */
export interface PostApiV1AgentAgentRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentRemoveResponseSuccess =
  PostApiV1AgentAgentRemoveResponse[200];
/**
 * @description
 *   删除代理
 * @tags 代理端-子代理管理
 * @x-apifox-folder "代理端-子代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/remove";
  function request(
    option?: PostApiV1AgentAgentRemoveOption
  ): Promise<PostApiV1AgentAgentRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentUserDetail */
export interface PostApiV1AgentUserDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserDetailRequest;
}

/** @description response type for postApiV1AgentUserDetail */
export interface PostApiV1AgentUserDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserDetailResponse;
  };
}

export type PostApiV1AgentUserDetailResponseSuccess =
  PostApiV1AgentUserDetailResponse[200];
/**
 * @description
 *   获取用户详情
 * @tags 代理端-用户管理
 * @x-apifox-folder "代理端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentUserDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/user/detail";
  function request(
    option?: PostApiV1AgentUserDetailOption
  ): Promise<PostApiV1AgentUserDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentUserDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminRoleCreate */
export interface PostApiV1AdminAdminRoleCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminRoleCreateRequest;
}

/** @description response type for postApiV1AdminAdminRoleCreate */
export interface PostApiV1AdminAdminRoleCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminRoleCreateResponseSuccess =
  PostApiV1AdminAdminRoleCreateResponse[200];
/**
 * @description
 *   创建角色
 * @tags 总管理后台-角色管理
 * @x-apifox-folder "总管理后台-角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminRoleCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/role/create";
  function request(
    option?: PostApiV1AdminAdminRoleCreateOption
  ): Promise<PostApiV1AdminAdminRoleCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminRoleCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeStatusChange */
export interface PostApiV1ApiEmployeeStatusChangeOption {
  body?: {
    /**
        @description
          员工ID */
    employee_id: string;
    /**
        @description
          行为 */
    action: number;
  };
}

/** @description response type for postApiV1ApiEmployeeStatusChange */
export interface PostApiV1ApiEmployeeStatusChangeResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeStatusChangeResponseSuccess =
  PostApiV1ApiEmployeeStatusChangeResponse[200];
/**
 * @description
 *   重置密码
 *   修改状态
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeStatusChange = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/status/change";
  function request(
    option?: PostApiV1ApiEmployeeStatusChangeOption
  ): Promise<PostApiV1ApiEmployeeStatusChangeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeStatusChangeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProfileModify */
export interface PostApiV1ApiProfileModifyOption {
  body?: {
    data?: {
      /**
        @description
          姓名 */
      name?: string;
      /**
        @description
          手机号 */
      mobile?: string;
      /**
        @description
          邮箱 */
      email?: string;
      /**
        @description
          头像 */
      avatar?: string;
    };
  };
}

/** @description response type for postApiV1ApiProfileModify */
export interface PostApiV1ApiProfileModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProfileModifyResponseSuccess =
  PostApiV1ApiProfileModifyResponse[200];
/**
 * @description
 *   编辑员工
 *   修改个人资料
 * @tags 客户端接口.个人账户资料
 * @x-apifox-folder "客户端接口.个人账户资料"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProfileModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/profile/modify";
  function request(
    option?: PostApiV1ApiProfileModifyOption
  ): Promise<PostApiV1ApiProfileModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProfileModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentAccountRemove */
export interface PostApiV1AgentAgentAccountRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountRemoveRequest;
}

/** @description response type for postApiV1AgentAgentAccountRemove */
export interface PostApiV1AgentAgentAccountRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentAccountRemoveResponseSuccess =
  PostApiV1AgentAgentAccountRemoveResponse[200];
/**
 * @description
 *   删除代理账号
 * @tags 代理端-代理账号管理
 * @x-apifox-folder "代理端-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentAccountRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/account/remove";
  function request(
    option?: PostApiV1AgentAgentAccountRemoveOption
  ): Promise<PostApiV1AgentAgentAccountRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentAccountRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeModify */
export interface PostApiV1ApiEmployeeModifyOption {
  body?: {
    /**
        @description
          员工ID */
    employee_id: string;
    data?: {
      /**
        @description
          姓名 */
      name?: string;
      /**
        @description
          手机号 */
      mobile?: string;
      /**
        @description
          邮箱 */
      email?: string;
      /**
        @description
          头像 */
      avatar?: string;
    };
  };
}

/** @description response type for postApiV1ApiEmployeeModify */
export interface PostApiV1ApiEmployeeModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeModifyResponseSuccess =
  PostApiV1ApiEmployeeModifyResponse[200];
/**
 * @description
 *   编辑员工
 *   编辑员工
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/modify";
  function request(
    option?: PostApiV1ApiEmployeeModifyOption
  ): Promise<PostApiV1ApiEmployeeModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentAccountList */
export interface PostApiV1AdminAgentAccountListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountListRequest;
}

/** @description response type for postApiV1AdminAgentAccountList */
export interface PostApiV1AdminAgentAccountListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentAccountListResponse;
  };
}

export type PostApiV1AdminAgentAccountListResponseSuccess =
  PostApiV1AdminAgentAccountListResponse[200];
/**
 * @description
 *   获取代理账号列表
 * @tags 总管理后台-代理账号管理
 * @x-apifox-folder "总管理后台-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentAccountList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/account/list";
  function request(
    option?: PostApiV1AdminAgentAccountListOption
  ): Promise<PostApiV1AdminAgentAccountListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentAccountListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserWithdrawCreate */
export interface PostApiV1UserWithdrawCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
    /**
        @description
        @example
           */
    "x-secret-code"?: string;
  };
  body?: WithdrawOrderCreateRequest;
}

/** @description response type for postApiV1UserWithdrawCreate */
export interface PostApiV1UserWithdrawCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1UserWithdrawCreateResponseSuccess =
  PostApiV1UserWithdrawCreateResponse[200];
/**
 * @description
 *   创建提现申请
 * @tags 用户端-提现申请管理
 * @x-apifox-folder "用户端-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserWithdrawCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/withdraw/create";
  function request(
    option?: PostApiV1UserWithdrawCreateOption
  ): Promise<PostApiV1UserWithdrawCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserWithdrawCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserWithdrawCancel */
export interface PostApiV1UserWithdrawCancelOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: WithdrawOrderCancelRequest;
}

/** @description response type for postApiV1UserWithdrawCancel */
export interface PostApiV1UserWithdrawCancelResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1UserWithdrawCancelResponseSuccess =
  PostApiV1UserWithdrawCancelResponse[200];
/**
 * @description
 *   取消提现申请
 * @tags 用户端-提现申请管理
 * @x-apifox-folder "用户端-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserWithdrawCancel = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/withdraw/cancel";
  function request(
    option?: PostApiV1UserWithdrawCancelOption
  ): Promise<PostApiV1UserWithdrawCancelResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserWithdrawCancelResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminProfileLogin */
export interface PostApiV1AdminProfileLoginOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: ProfileLoginRequest;
}

/** @description response type for postApiV1AdminProfileLogin */
export interface PostApiV1AdminProfileLoginResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ProfileLoginResponse;
  };
}

export type PostApiV1AdminProfileLoginResponseSuccess =
  PostApiV1AdminProfileLoginResponse[200];
/**
 * @description
 *   登录
 * @tags 总管理后台-Profile
 * @x-apifox-folder "总管理后台-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AdminProfileLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/profile/login";
  function request(
    option?: PostApiV1AdminProfileLoginOption
  ): Promise<PostApiV1AdminProfileLoginResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminProfileLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentStatsSnapshotDetail */
export interface PostApiV1AgentAgentStatsSnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AgentAgentStatsSnapshotDetail */
export interface PostApiV1AgentAgentStatsSnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentStatsSnapshotDetailResponse;
  };
}

export type PostApiV1AgentAgentStatsSnapshotDetailResponseSuccess =
  PostApiV1AgentAgentStatsSnapshotDetailResponse[200];
/**
 * @description
 *   获取统计详情
 * @tags 代理端-统计管理
 * @x-apifox-folder "代理端-统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentStatsSnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/stats/snapshot/detail";
  function request(
    option?: PostApiV1AgentAgentStatsSnapshotDetailOption
  ): Promise<PostApiV1AgentAgentStatsSnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentStatsSnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeePasswordReset */
export interface PostApiV1ApiEmployeePasswordResetOption {
  body?: {
    /**
        @description
          员工ID */
    employee_id: string;
  };
}

/** @description response type for postApiV1ApiEmployeePasswordReset */
export interface PostApiV1ApiEmployeePasswordResetResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeePasswordResetResponseSuccess =
  PostApiV1ApiEmployeePasswordResetResponse[200];
/**
 * @description
 *   重置密码
 *   重置密码
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeePasswordReset = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/password/reset";
  function request(
    option?: PostApiV1ApiEmployeePasswordResetOption
  ): Promise<PostApiV1ApiEmployeePasswordResetResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeePasswordResetResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentOrderDetail */
export interface PostApiV1AgentOrderDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderDetailRequest;
}

/** @description response type for postApiV1AgentOrderDetail */
export interface PostApiV1AgentOrderDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderDetailResponse;
  };
}

export type PostApiV1AgentOrderDetailResponseSuccess =
  PostApiV1AgentOrderDetailResponse[200];
/**
 * @description
 *   获取订单详情
 * @tags 代理端-订单管理
 * @x-apifox-folder "代理端-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentOrderDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/order/detail";
  function request(
    option?: PostApiV1AgentOrderDetailOption
  ): Promise<PostApiV1AgentOrderDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentOrderDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeRemove */
export interface PostApiV1ApiEmployeeRemoveOption {
  body?: {
    /**
        @description
          员工ID */
    employee_id: string;
  };
}

/** @description response type for postApiV1ApiEmployeeRemove */
export interface PostApiV1ApiEmployeeRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeRemoveResponseSuccess =
  PostApiV1ApiEmployeeRemoveResponse[200];
/**
 * @description
 *   删除员工
 *   删除员工
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/remove";
  function request(
    option?: PostApiV1ApiEmployeeRemoveOption
  ): Promise<PostApiV1ApiEmployeeRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentCreate */
export interface PostApiV1AgentAgentCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentCreateRequest;
}

/** @description response type for postApiV1AgentAgentCreate */
export interface PostApiV1AgentAgentCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentCreateResponseSuccess =
  PostApiV1AgentAgentCreateResponse[200];
/**
 * @description
 *   创建代理
 * @tags 代理端-子代理管理
 * @x-apifox-folder "代理端-子代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/create";
  function request(
    option?: PostApiV1AgentAgentCreateOption
  ): Promise<PostApiV1AgentAgentCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiSummaryDetail */
export interface PostApiV1ApiSummaryDetailOption {
  body?: {
    /**
        @description
          总结ID */
    summary_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: string;
    };
  };
}

/** @description response type for postApiV1ApiSummaryDetail */
export interface PostApiV1ApiSummaryDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiSummaryDetailResponseSuccess =
  PostApiV1ApiSummaryDetailResponse[200];
/**
 * @description
 *   获取总结详情
 *   获取总结详情
 * @tags 客户端接口.总结
 * @x-apifox-folder "客户端接口.总结"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiSummaryDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/summary/detail";
  function request(
    option?: PostApiV1ApiSummaryDetailOption
  ): Promise<PostApiV1ApiSummaryDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiSummaryDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentList */
export interface PostApiV1AdminAgentListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentListRequest;
}

/** @description response type for postApiV1AdminAgentList */
export interface PostApiV1AdminAgentListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentListResponse;
  };
}

export type PostApiV1AdminAgentListResponseSuccess =
  PostApiV1AdminAgentListResponse[200];
/**
 * @description
 *   获取代理列表
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/list";
  function request(
    option?: PostApiV1AdminAgentListOption
  ): Promise<PostApiV1AdminAgentListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentAccountDetail */
export interface PostApiV1AgentAgentAccountDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountDetailRequest;
}

/** @description response type for postApiV1AgentAgentAccountDetail */
export interface PostApiV1AgentAgentAccountDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentAccountDetailResponse;
  };
}

export type PostApiV1AgentAgentAccountDetailResponseSuccess =
  PostApiV1AgentAgentAccountDetailResponse[200];
/**
 * @description
 *   获取代理账号详情
 * @tags 代理端-代理账号管理
 * @x-apifox-folder "代理端-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentAccountDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/account/detail";
  function request(
    option?: PostApiV1AgentAgentAccountDetailOption
  ): Promise<PostApiV1AgentAgentAccountDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentAccountDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountCreate */
export interface PostApiV1AdminAdminAccountCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountCreateRequest;
}

/** @description response type for postApiV1AdminAdminAccountCreate */
export interface PostApiV1AdminAdminAccountCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminAccountCreateResponseSuccess =
  PostApiV1AdminAdminAccountCreateResponse[200];
/**
 * @description
 *   创建管理账号
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/create";
  function request(
    option?: PostApiV1AdminAdminAccountCreateOption
  ): Promise<PostApiV1AdminAdminAccountCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentStatsDailySnapshotList */
export interface PostApiV1AdminAgentStatsDailySnapshotListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentStatsDailySnapshotListRequest;
}

/** @description response type for postApiV1AdminAgentStatsDailySnapshotList */
export interface PostApiV1AdminAgentStatsDailySnapshotListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentStatsDailySnapshotListResponse;
  };
}

export type PostApiV1AdminAgentStatsDailySnapshotListResponseSuccess =
  PostApiV1AdminAgentStatsDailySnapshotListResponse[200];
/**
 * @description
 *   获取汇总统计列表
 * @tags 总管理后台-代理汇总统计管理
 * @x-apifox-folder "总管理后台-代理汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentStatsDailySnapshotList =
  /* #__PURE__ */ (() => {
    const method = "post";
    const url = "/api/v1/admin/agent/stats/daily/snapshot/list";
    function request(
      option?: PostApiV1AdminAgentStatsDailySnapshotListOption
    ): Promise<PostApiV1AdminAgentStatsDailySnapshotListResponseSuccess> {
      return requester(url, {
        method,
        ...option,
      }) as unknown as Promise<PostApiV1AdminAgentStatsDailySnapshotListResponseSuccess>;
    }

    /** http method */
    request.method = method;
    /** request url */
    request.url = url;
    return request;
  })();

/** @description request parameter type for postApiV1ApiProgramCreate */
export interface PostApiV1ApiProgramCreateOption {
  body?: {
    data?: {
      /**
        @description
          项目名称 */
      program_name?: string;
      /**
        @description
          项目标识 */
      label?: string;
      /**
        @description
          项目描述 */
      description?: string;
      /**
        @description
          项目成员ID
        @default
        @example
           */
      employees?: Array<string>;
    };
  };
}

/** @description response type for postApiV1ApiProgramCreate */
export interface PostApiV1ApiProgramCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCreateResponseSuccess =
  PostApiV1ApiProgramCreateResponse[200];
/**
 * @description
 *   创建项目
 *   创建项目
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/create";
  function request(
    option?: PostApiV1ApiProgramCreateOption
  ): Promise<PostApiV1ApiProgramCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminWithdrawOrderPass */
export interface PostApiV1AdminWithdrawOrderPassOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: WithdrawOrderModifyRequest;
}

/** @description response type for postApiV1AdminWithdrawOrderPass */
export interface PostApiV1AdminWithdrawOrderPassResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminWithdrawOrderPassResponseSuccess =
  PostApiV1AdminWithdrawOrderPassResponse[200];
/**
 * @description
 *   通过提现申请
 * @tags 总管理后台-提现申请管理
 * @x-apifox-folder "总管理后台-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminWithdrawOrderPass = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/withdrawOrder/pass";
  function request(
    option?: PostApiV1AdminWithdrawOrderPassOption
  ): Promise<PostApiV1AdminWithdrawOrderPassResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminWithdrawOrderPassResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminBlacklistList */
export interface PostApiV1AdminBlacklistListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: BlacklistListRequest;
}

/** @description response type for postApiV1AdminBlacklistList */
export interface PostApiV1AdminBlacklistListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BlacklistListResponse;
  };
}

export type PostApiV1AdminBlacklistListResponseSuccess =
  PostApiV1AdminBlacklistListResponse[200];
/**
 * @description
 *   获取黑名单列表
 * @tags 总管理后台-黑名单管理
 * @x-apifox-folder "总管理后台-黑名单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminBlacklistList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/blacklist/list";
  function request(
    option?: PostApiV1AdminBlacklistListOption
  ): Promise<PostApiV1AdminBlacklistListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminBlacklistListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleModify */
export interface PostApiV1ApiProgramCycleModifyOption {
  body?: {
    /**
        @description
          周期ID */
    cycle_id: string;
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          周期名称 */
      name?: string;
      /**
        @description
          周期开始时间 */
      start_at?: number;
      /**
        @description
          周期结束时间 */
      end_at?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramCycleModify */
export interface PostApiV1ApiProgramCycleModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleModifyResponseSuccess =
  PostApiV1ApiProgramCycleModifyResponse[200];
/**
 * @description
 *   编辑周期
 *   编辑周期
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/modify";
  function request(
    option?: PostApiV1ApiProgramCycleModifyOption
  ): Promise<PostApiV1ApiProgramCycleModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultList */
export interface PostApiV1ApiTaskResultListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询发布人信息 */
      with_employee?: number;
      /**
        @description
          关联条件.查询周期信息 */
      with_cycle?: number;
      /**
        @description
          关联条件.查询阶段信息 */
      with_stage?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务ID */
      task_id?: string;
      /**
        @description
          搜索条件.阶段ID */
      stage_id?: string;
      /**
        @description
          搜索条件.任务成果状态 */
      status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskResultList */
export interface PostApiV1ApiTaskResultListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultListResponseSuccess =
  PostApiV1ApiTaskResultListResponse[200];
/**
 * @description
 *   获取任务成果列表
 *   获取任务成果列表
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/list";
  function request(
    option?: PostApiV1ApiTaskResultListOption
  ): Promise<PostApiV1ApiTaskResultListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStatsSnapshotDetail */
export interface PostApiV1AdminStatsSnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AdminStatsSnapshotDetail */
export interface PostApiV1AdminStatsSnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StatsSnapshotDetailResponse;
  };
}

export type PostApiV1AdminStatsSnapshotDetailResponseSuccess =
  PostApiV1AdminStatsSnapshotDetailResponse[200];
/**
 * @description
 *   获取统计详情
 * @tags 总管理后台-统计管理
 * @x-apifox-folder "总管理后台-统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStatsSnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/stats/snapshot/detail";
  function request(
    option?: PostApiV1AdminStatsSnapshotDetailOption
  ): Promise<PostApiV1AdminStatsSnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStatsSnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentModify */
export interface PostApiV1AdminAgentModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentModifyRequest;
}

/** @description response type for postApiV1AdminAgentModify */
export interface PostApiV1AdminAgentModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAgentModifyResponseSuccess =
  PostApiV1AdminAgentModifyResponse[200];
/**
 * @description
 *   更新代理
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/modify";
  function request(
    option?: PostApiV1AdminAgentModifyOption
  ): Promise<PostApiV1AdminAgentModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentProfileLogin */
export interface PostApiV1AgentProfileLoginOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: ProfileLoginRequest;
}

/** @description response type for postApiV1AgentProfileLogin */
export interface PostApiV1AgentProfileLoginResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ProfileLoginResponse;
  };
}

export type PostApiV1AgentProfileLoginResponseSuccess =
  PostApiV1AgentProfileLoginResponse[200];
/**
 * @description
 *   登录
 * @tags 代理端-Profile
 * @x-apifox-folder "代理端-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AgentProfileLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/profile/login";
  function request(
    option?: PostApiV1AgentProfileLoginOption
  ): Promise<PostApiV1AgentProfileLoginResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentProfileLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserWithdrawList */
export interface PostApiV1UserWithdrawListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: WithdrawOrderListRequest;
}

/** @description response type for postApiV1UserWithdrawList */
export interface PostApiV1UserWithdrawListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: WithdrawOrderListResponse;
  };
}

export type PostApiV1UserWithdrawListResponseSuccess =
  PostApiV1UserWithdrawListResponse[200];
/**
 * @description
 *   获取提现申请列表
 * @tags 用户端-提现申请管理
 * @x-apifox-folder "用户端-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserWithdrawList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/withdraw/list";
  function request(
    option?: PostApiV1UserWithdrawListOption
  ): Promise<PostApiV1UserWithdrawListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserWithdrawListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserOrderList */
export interface PostApiV1UserOrderListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderListRequest;
}

/** @description response type for postApiV1UserOrderList */
export interface PostApiV1UserOrderListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderListResponse;
  };
}

export type PostApiV1UserOrderListResponseSuccess =
  PostApiV1UserOrderListResponse[200];
/**
 * @description
 *   获取订单列表
 * @tags 用户端-订单管理
 * @x-apifox-folder "用户端-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserOrderList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/order/list";
  function request(
    option?: PostApiV1UserOrderListOption
  ): Promise<PostApiV1UserOrderListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserOrderListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserList */
export interface PostApiV1AdminUserListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserListRequest;
}

/** @description response type for postApiV1AdminUserList */
export interface PostApiV1AdminUserListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserListResponse;
  };
}

export type PostApiV1AdminUserListResponseSuccess =
  PostApiV1AdminUserListResponse[200];
/**
 * @description
 *   获取用户列表
 * @tags 总管理后台-用户管理
 * @x-apifox-folder "总管理后台-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/list";
  function request(
    option?: PostApiV1AdminUserListOption
  ): Promise<PostApiV1AdminUserListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEnterpriseDetail */
export interface PostApiV1ApiEnterpriseDetailOption {
  body?: {
    /**
        @description
          企业ID */
    enterprise_id: string;
  };
}

/** @description response type for postApiV1ApiEnterpriseDetail */
export interface PostApiV1ApiEnterpriseDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEnterpriseDetailResponseSuccess =
  PostApiV1ApiEnterpriseDetailResponse[200];
/**
 * @description
 *   获取企业详情
 *   获取企业详情
 * @tags 客户端接口.企业
 * @x-apifox-folder "客户端接口.企业"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEnterpriseDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/enterprise/detail";
  function request(
    option?: PostApiV1ApiEnterpriseDetailOption
  ): Promise<PostApiV1ApiEnterpriseDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEnterpriseDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreList */
export interface PostApiV1AdminStoreListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreListRequest;
}

/** @description response type for postApiV1AdminStoreList */
export interface PostApiV1AdminStoreListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StoreListResponse;
  };
}

export type PostApiV1AdminStoreListResponseSuccess =
  PostApiV1AdminStoreListResponse[200];
/**
 * @description
 *   获取站点列表
 * @tags 总管理后台-站点管理
 * @x-apifox-folder "总管理后台-站点管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/list";
  function request(
    option?: PostApiV1AdminStoreListOption
  ): Promise<PostApiV1AdminStoreListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description response type for postApiV1TestOrderGenerate */
export interface PostApiV1TestOrderGenerateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ResponseInterface;
  };
}

export type PostApiV1TestOrderGenerateResponseSuccess =
  PostApiV1TestOrderGenerateResponse[200];
/**
 * @description
 *   生成地址
 * @tags 测试接口-订单管理
 * @x-apifox-folder "测试接口-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1TestOrderGenerate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/test/order/generate";
  function request(): Promise<PostApiV1TestOrderGenerateResponseSuccess> {
    return requester(url, {
      method,
    }) as unknown as Promise<PostApiV1TestOrderGenerateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultCreate */
export interface PostApiV1ApiTaskResultCreateOption {
  body?: {
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          项目ID */
      program_id?: string;
      /**
        @description
          阶段ID */
      stage_id?: string;
      /**
        @description
          周期ID */
      cycle_id?: string;
      /**
        @description
          成果内容 */
      result?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskResultCreate */
export interface PostApiV1ApiTaskResultCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultCreateResponseSuccess =
  PostApiV1ApiTaskResultCreateResponse[200];
/**
 * @description
 *   创建任务成果
 *   创建任务成果
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/create";
  function request(
    option?: PostApiV1ApiTaskResultCreateOption
  ): Promise<PostApiV1ApiTaskResultCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserCoinList */
export interface PostApiV1UserCoinListOption {
  body?: CoinListRequest;
}

/** @description response type for postApiV1UserCoinList */
export interface PostApiV1UserCoinListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: CoinListResponse;
  };
}

export type PostApiV1UserCoinListResponseSuccess =
  PostApiV1UserCoinListResponse[200];
/**
 * @description
 *   获取币种列表
 * @tags 用户端-币种管理
 * @x-apifox-folder "用户端-币种管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserCoinList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/coin/list";
  function request(
    option?: PostApiV1UserCoinListOption
  ): Promise<PostApiV1UserCoinListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserCoinListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskHandle */
export interface PostApiV1ApiTaskHandleOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          任务ID */
    task_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiTaskHandle */
export interface PostApiV1ApiTaskHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskHandleResponseSuccess =
  PostApiV1ApiTaskHandleResponse[200];
/**
 * @description
 *   操作任务
 *   操作任务
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/handle";
  function request(
    option?: PostApiV1ApiTaskHandleOption
  ): Promise<PostApiV1ApiTaskHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryCreate */
export interface PostApiV1ApiTaskSummaryCreateOption {
  body?: {
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          项目ID */
      program_id?: string;
      /**
        @description
          阶段ID */
      stage_id?: string;
      /**
        @description
          周期ID */
      cycle_id?: string;
      /**
        @description
          总结类型 */
      type?: number;
      /**
        @description
          总结内容 */
      result?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskSummaryCreate */
export interface PostApiV1ApiTaskSummaryCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryCreateResponseSuccess =
  PostApiV1ApiTaskSummaryCreateResponse[200];
/**
 * @description
 *   创建任务总结报告
 *   创建任务总结报告
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/create";
  function request(
    option?: PostApiV1ApiTaskSummaryCreateOption
  ): Promise<PostApiV1ApiTaskSummaryCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleList */
export interface PostApiV1ApiProgramCycleListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.阶段信息 */
      with_stage?: number;
    };
    search?: {
      /**
        @description
          搜索条件.阶段ID */
      stage_id?: string;
      /**
        @description
          搜索条件.周期状态 */
      status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramCycleList */
export interface PostApiV1ApiProgramCycleListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleListResponseSuccess =
  PostApiV1ApiProgramCycleListResponse[200];
/**
 * @description
 *   获取周期列表
 *   获取周期列表
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/list";
  function request(
    option?: PostApiV1ApiProgramCycleListOption
  ): Promise<PostApiV1ApiProgramCycleListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminCoinList */
export interface PostApiV1AdminCoinListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: CoinListRequest;
}

/** @description response type for postApiV1AdminCoinList */
export interface PostApiV1AdminCoinListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: CoinListResponse;
  };
}

export type PostApiV1AdminCoinListResponseSuccess =
  PostApiV1AdminCoinListResponse[200];
/**
 * @description
 *   获取币种列表
 * @tags 总管理后台-币种管理
 * @x-apifox-folder "总管理后台-币种管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminCoinList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/coin/list";
  function request(
    option?: PostApiV1AdminCoinListOption
  ): Promise<PostApiV1AdminCoinListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminCoinListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminBlacklistRemove */
export interface PostApiV1AdminBlacklistRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: BlacklistRemoveRequest;
}

/** @description response type for postApiV1AdminBlacklistRemove */
export interface PostApiV1AdminBlacklistRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminBlacklistRemoveResponseSuccess =
  PostApiV1AdminBlacklistRemoveResponse[200];
/**
 * @description
 *   删除黑名单
 * @tags 总管理后台-黑名单管理
 * @x-apifox-folder "总管理后台-黑名单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminBlacklistRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/blacklist/remove";
  function request(
    option?: PostApiV1AdminBlacklistRemoveOption
  ): Promise<PostApiV1AdminBlacklistRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminBlacklistRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProfileDetail */
export interface PostApiV1ApiProfileDetailOption {
  body?: {};
}

/** @description response type for postApiV1ApiProfileDetail */
export interface PostApiV1ApiProfileDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProfileDetailResponseSuccess =
  PostApiV1ApiProfileDetailResponse[200];
/**
 * @description
 *   个人账户信息
 *   个人账户信息
 * @tags 客户端接口.个人账户资料
 * @x-apifox-folder "客户端接口.个人账户资料"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProfileDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/profile/detail";
  function request(
    option?: PostApiV1ApiProfileDetailOption
  ): Promise<PostApiV1ApiProfileDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProfileDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramEmployeeCreate */
export interface PostApiV1ApiProgramEmployeeCreateOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          成员ID
        @default
        @example
           */
    employees: Array<string>;
    /**
        @description
          读写任务ID
        @default
        @example
           */
    rw_list: Array<string>;
    /**
        @description
          只读任务ID
        @default
        @example
           */
    r_list: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramEmployeeCreate */
export interface PostApiV1ApiProgramEmployeeCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramEmployeeCreateResponseSuccess =
  PostApiV1ApiProgramEmployeeCreateResponse[200];
/**
 * @description
 *   创建项目成员管理
 *   创建项目成员管理
 * @tags 客户端接口.项目成员管理
 * @x-apifox-folder "客户端接口.项目成员管理"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramEmployeeCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/employee/create";
  function request(
    option?: PostApiV1ApiProgramEmployeeCreateOption
  ): Promise<PostApiV1ApiProgramEmployeeCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramEmployeeCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskStageList */
export interface PostApiV1ApiTaskStageListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询阶段信息 */
      with_stage?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务ID */
      task_id?: number;
      /**
        @description
          搜索条件.阶段状态 */
      stage_status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskStageList */
export interface PostApiV1ApiTaskStageListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskStageListResponseSuccess =
  PostApiV1ApiTaskStageListResponse[200];
/**
 * @description
 *   获取任务阶段列表
 *   获取任务阶段列表
 * @tags 客户端接口.任务关联阶段
 * @x-apifox-folder "客户端接口.任务关联阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskStageList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/stage/list";
  function request(
    option?: PostApiV1ApiTaskStageListOption
  ): Promise<PostApiV1ApiTaskStageListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskStageListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserBalanceRecordList */
export interface PostApiV1UserUserBalanceRecordListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserBalanceRecordListRequest;
}

/** @description response type for postApiV1UserUserBalanceRecordList */
export interface PostApiV1UserUserBalanceRecordListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserBalanceRecordListResponse;
  };
}

export type PostApiV1UserUserBalanceRecordListResponseSuccess =
  PostApiV1UserUserBalanceRecordListResponse[200];
/**
 * @description
 *   获取列表
 * @tags 用户端-用户资产记录管理
 * @x-apifox-folder "用户端-用户资产记录管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserBalanceRecordList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/user/balance/record/list";
  function request(
    option?: PostApiV1UserUserBalanceRecordListOption
  ): Promise<PostApiV1UserUserBalanceRecordListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserUserBalanceRecordListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRoleDetail */
export interface PostApiV1AgentAgentRoleDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRoleDetailRequest;
}

/** @description response type for postApiV1AgentAgentRoleDetail */
export interface PostApiV1AgentAgentRoleDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentRoleDetailResponse;
  };
}

export type PostApiV1AgentAgentRoleDetailResponseSuccess =
  PostApiV1AgentAgentRoleDetailResponse[200];
/**
 * @description
 *   获取代理角色详情
 * @tags 代理端-代理角色管理
 * @x-apifox-folder "代理端-代理角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRoleDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/role/detail";
  function request(
    option?: PostApiV1AgentAgentRoleDetailOption
  ): Promise<PostApiV1AgentAgentRoleDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRoleDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentBonusRecordList */
export interface PostApiV1AdminAgentBonusRecordListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentBonusRecordListRequest;
}

/** @description response type for postApiV1AdminAgentBonusRecordList */
export interface PostApiV1AdminAgentBonusRecordListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentBonusRecordListResponse;
  };
}

export type PostApiV1AdminAgentBonusRecordListResponseSuccess =
  PostApiV1AdminAgentBonusRecordListResponse[200];
/**
 * @description
 *   获取代理分红记录列表
 * @tags 总管理后台-代理分红记录管理
 * @x-apifox-folder "总管理后台-代理分红记录管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentBonusRecordList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/bonus/record/list";
  function request(
    option?: PostApiV1AdminAgentBonusRecordListOption
  ): Promise<PostApiV1AdminAgentBonusRecordListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentBonusRecordListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1InnerOrderWithdrawCallback */
export interface PostApiV1InnerOrderWithdrawCallbackOption {
  body?: WithdrawCallbackRequest;
}

/** @description response type for postApiV1InnerOrderWithdrawCallback */
export interface PostApiV1InnerOrderWithdrawCallbackResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ResponseInterface;
  };
}

export type PostApiV1InnerOrderWithdrawCallbackResponseSuccess =
  PostApiV1InnerOrderWithdrawCallbackResponse[200];
/**
 * @description
 *   转出完成通知
 * @tags 内部接口-订单
 * @x-apifox-folder "内部接口-订单"
 * @x-apifox-status "released"
 */
export const postApiV1InnerOrderWithdrawCallback = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/inner/order/withdraw/callback";
  function request(
    option?: PostApiV1InnerOrderWithdrawCallbackOption
  ): Promise<PostApiV1InnerOrderWithdrawCallbackResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1InnerOrderWithdrawCallbackResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskEmployeeList */
export interface PostApiV1ApiTaskEmployeeListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.员工信息 */
      with_employee?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务ID */
      task_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskEmployeeList */
export interface PostApiV1ApiTaskEmployeeListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskEmployeeListResponseSuccess =
  PostApiV1ApiTaskEmployeeListResponse[200];
/**
 * @description
 *   获取任务成员列表
 *   获取任务成员列表
 * @tags 客户端接口.任务成员
 * @x-apifox-folder "客户端接口.任务成员"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskEmployeeList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/employee/list";
  function request(
    option?: PostApiV1ApiTaskEmployeeListOption
  ): Promise<PostApiV1ApiTaskEmployeeListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskEmployeeListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentAccountCreate */
export interface PostApiV1AgentAgentAccountCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentAccountCreateRequest;
}

/** @description response type for postApiV1AgentAgentAccountCreate */
export interface PostApiV1AgentAgentAccountCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentAccountCreateResponseSuccess =
  PostApiV1AgentAgentAccountCreateResponse[200];
/**
 * @description
 *   创建代理账号
 * @tags 代理端-代理账号管理
 * @x-apifox-folder "代理端-代理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentAccountCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/account/create";
  function request(
    option?: PostApiV1AgentAgentAccountCreateOption
  ): Promise<PostApiV1AgentAgentAccountCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentAccountCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRoleModify */
export interface PostApiV1AgentAgentRoleModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRoleModifyRequest;
}

/** @description response type for postApiV1AgentAgentRoleModify */
export interface PostApiV1AgentAgentRoleModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentRoleModifyResponseSuccess =
  PostApiV1AgentAgentRoleModifyResponse[200];
/**
 * @description
 *   更新代理角色
 * @tags 代理端-代理角色管理
 * @x-apifox-folder "代理端-代理角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRoleModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/role/modify";
  function request(
    option?: PostApiV1AgentAgentRoleModifyOption
  ): Promise<PostApiV1AgentAgentRoleModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRoleModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentDetail */
export interface PostApiV1AgentAgentDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentDetailRequest;
}

/** @description response type for postApiV1AgentAgentDetail */
export interface PostApiV1AgentAgentDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentDetailResponse;
  };
}

export type PostApiV1AgentAgentDetailResponseSuccess =
  PostApiV1AgentAgentDetailResponse[200];
/**
 * @description
 *   获取代理详情
 * @tags 代理端-子代理管理
 * @x-apifox-folder "代理端-子代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/detail";
  function request(
    option?: PostApiV1AgentAgentDetailOption
  ): Promise<PostApiV1AgentAgentDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserStatsSnapshotList */
export interface PostApiV1AdminUserStatsSnapshotListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsSnapshotListRequest;
}

/** @description response type for postApiV1AdminUserStatsSnapshotList */
export interface PostApiV1AdminUserStatsSnapshotListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsSnapshotListResponse;
  };
}

export type PostApiV1AdminUserStatsSnapshotListResponseSuccess =
  PostApiV1AdminUserStatsSnapshotListResponse[200];
/**
 * @description
 *   获取统计列表
 * @tags 总管理后台-用户统计管理
 * @x-apifox-folder "总管理后台-用户统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserStatsSnapshotList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/stats/snapshot/list";
  function request(
    option?: PostApiV1AdminUserStatsSnapshotListOption
  ): Promise<PostApiV1AdminUserStatsSnapshotListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserStatsSnapshotListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminWithdrawOrderList */
export interface PostApiV1AdminWithdrawOrderListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: WithdrawOrderListRequest;
}

/** @description response type for postApiV1AdminWithdrawOrderList */
export interface PostApiV1AdminWithdrawOrderListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: WithdrawOrderListResponse;
  };
}

export type PostApiV1AdminWithdrawOrderListResponseSuccess =
  PostApiV1AdminWithdrawOrderListResponse[200];
/**
 * @description
 *   获取提现申请列表
 * @tags 总管理后台-提现申请管理
 * @x-apifox-folder "总管理后台-提现申请管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminWithdrawOrderList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/withdrawOrder/list";
  function request(
    option?: PostApiV1AdminWithdrawOrderListOption
  ): Promise<PostApiV1AdminWithdrawOrderListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminWithdrawOrderListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminRoleList */
export interface PostApiV1AdminAdminRoleListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminRoleListRequest;
}

/** @description response type for postApiV1AdminAdminRoleList */
export interface PostApiV1AdminAdminRoleListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminRoleListResponse;
  };
}

export type PostApiV1AdminAdminRoleListResponseSuccess =
  PostApiV1AdminAdminRoleListResponse[200];
/**
 * @description
 *   获取角色列表
 * @tags 总管理后台-角色管理
 * @x-apifox-folder "总管理后台-角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminRoleList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/role/list";
  function request(
    option?: PostApiV1AdminAdminRoleListOption
  ): Promise<PostApiV1AdminAdminRoleListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminRoleListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskCycleList */
export interface PostApiV1ApiTaskCycleListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询周期信息 */
      with_cycle?: number;
      /**
        @description
          关联条件.查询阶段信息 */
      with_stage?: number;
      /**
        @description
          关联条件.查询任务信息 */
      with_task?: number;
      /**
        @description
          关联条件.查询任务负责人信息 */
      with_director?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务ID */
      task_id?: string;
      /**
        @description
          搜索条件.任务类型 */
      task_type?: string;
      /**
        @description
          搜索条件.阶段状态 */
      stage_status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskCycleList */
export interface PostApiV1ApiTaskCycleListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskCycleListResponseSuccess =
  PostApiV1ApiTaskCycleListResponse[200];
/**
 * @description
 *   获取任务周期列表
 *   获取任务周期列表
 * @tags 客户端接口.任务关联周期
 * @x-apifox-folder "客户端接口.任务关联周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskCycleList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/cycle/list";
  function request(
    option?: PostApiV1ApiTaskCycleListOption
  ): Promise<PostApiV1ApiTaskCycleListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskCycleListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminCoinModify */
export interface PostApiV1AdminCoinModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: CoinModifyRequest;
}

/** @description response type for postApiV1AdminCoinModify */
export interface PostApiV1AdminCoinModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminCoinModifyResponseSuccess =
  PostApiV1AdminCoinModifyResponse[200];
/**
 * @description
 *   更新币种
 * @tags 总管理后台-币种管理
 * @x-apifox-folder "总管理后台-币种管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminCoinModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/coin/modify";
  function request(
    option?: PostApiV1AdminCoinModifyOption
  ): Promise<PostApiV1AdminCoinModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminCoinModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskEmployeeCreate */
export interface PostApiV1ApiTaskEmployeeCreateOption {
  body?: {
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          项目ID */
      program_id?: string;
      employee_list?: Array<{
        /**
        @description
          用户ID */
        employee_id?: string;
        /**
        @description
          是否只读 */
        is_readonly?: number;
      }>;
    };
  };
}

/** @description response type for postApiV1ApiTaskEmployeeCreate */
export interface PostApiV1ApiTaskEmployeeCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskEmployeeCreateResponseSuccess =
  PostApiV1ApiTaskEmployeeCreateResponse[200];
/**
 * @description
 *   创建任务成员
 *   创建任务成员
 * @tags 客户端接口.任务成员
 * @x-apifox-folder "客户端接口.任务成员"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskEmployeeCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/employee/create";
  function request(
    option?: PostApiV1ApiTaskEmployeeCreateOption
  ): Promise<PostApiV1ApiTaskEmployeeCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskEmployeeCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentRemove */
export interface PostApiV1AdminAgentRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRemoveRequest;
}

/** @description response type for postApiV1AdminAgentRemove */
export interface PostApiV1AdminAgentRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAgentRemoveResponseSuccess =
  PostApiV1AdminAgentRemoveResponse[200];
/**
 * @description
 *   删除代理
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/remove";
  function request(
    option?: PostApiV1AdminAgentRemoveOption
  ): Promise<PostApiV1AdminAgentRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentUserList */
export interface PostApiV1AgentUserListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserListRequest;
}

/** @description response type for postApiV1AgentUserList */
export interface PostApiV1AgentUserListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserListResponse;
  };
}

export type PostApiV1AgentUserListResponseSuccess =
  PostApiV1AgentUserListResponse[200];
/**
 * @description
 *   获取用户列表
 * @tags 代理端-用户管理
 * @x-apifox-folder "代理端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentUserList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/user/list";
  function request(
    option?: PostApiV1AgentUserListOption
  ): Promise<PostApiV1AgentUserListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentUserListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserTotalStats */
export interface PostApiV1AdminUserTotalStatsOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsDailySnapshotDetailRequest;
}

/** @description response type for postApiV1AdminUserTotalStats */
export interface PostApiV1AdminUserTotalStatsResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsDailySnapshotDetailResponse;
  };
}

export type PostApiV1AdminUserTotalStatsResponseSuccess =
  PostApiV1AdminUserTotalStatsResponse[200];
/**
 * @description
 *   获取用户累计统计
 * @tags 总管理后台-用户管理
 * @x-apifox-folder "总管理后台-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserTotalStats = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/total/stats";
  function request(
    option?: PostApiV1AdminUserTotalStatsOption
  ): Promise<PostApiV1AdminUserTotalStatsResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserTotalStatsResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserGameList */
export interface PostApiV1UserGameListOption {
  body?: GameListRequest;
}

/** @description response type for postApiV1UserGameList */
export interface PostApiV1UserGameListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: GameListResponse;
  };
}

export type PostApiV1UserGameListResponseSuccess =
  PostApiV1UserGameListResponse[200];
/**
 * @description
 *   获取游戏列表
 * @tags 用户端-游戏管理
 * @x-apifox-folder "用户端-游戏管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserGameList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/game/list";
  function request(
    option?: PostApiV1UserGameListOption
  ): Promise<PostApiV1UserGameListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserGameListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserBalanceDetail */
export interface PostApiV1UserUserBalanceDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserBalanceDetailRequest;
}

/** @description response type for postApiV1UserUserBalanceDetail */
export interface PostApiV1UserUserBalanceDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserBalanceDetailResponse;
  };
}

export type PostApiV1UserUserBalanceDetailResponseSuccess =
  PostApiV1UserUserBalanceDetailResponse[200];
/**
 * @description
 *   获取用户余额详情
 * @tags 用户端-用户余额管理
 * @x-apifox-folder "用户端-用户余额管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserBalanceDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/user/balance/detail";
  function request(
    option?: PostApiV1UserUserBalanceDetailOption
  ): Promise<PostApiV1UserUserBalanceDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserUserBalanceDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramRemove */
export interface PostApiV1ApiProgramRemoveOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
  };
}

/** @description response type for postApiV1ApiProgramRemove */
export interface PostApiV1ApiProgramRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramRemoveResponseSuccess =
  PostApiV1ApiProgramRemoveResponse[200];
/**
 * @description
 *   删除项目
 *   删除项目
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/remove";
  function request(
    option?: PostApiV1ApiProgramRemoveOption
  ): Promise<PostApiV1ApiProgramRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStatsDailySnapshotDetail */
export interface PostApiV1AdminStatsDailySnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StatsDailySnapshotDetailRequest;
}

/** @description response type for postApiV1AdminStatsDailySnapshotDetail */
export interface PostApiV1AdminStatsDailySnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StatsDailySnapshotDetailResponse;
  };
}

export type PostApiV1AdminStatsDailySnapshotDetailResponseSuccess =
  PostApiV1AdminStatsDailySnapshotDetailResponse[200];
/**
 * @description
 *   获取汇总统计详情
 * @tags 总管理后台-汇总统计管理
 * @x-apifox-folder "总管理后台-汇总统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStatsDailySnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/stats/daily/snapshot/detail";
  function request(
    option?: PostApiV1AdminStatsDailySnapshotDetailOption
  ): Promise<PostApiV1AdminStatsDailySnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStatsDailySnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeCreate */
export interface PostApiV1ApiEmployeeCreateOption {
  body?: {
    data?: {
      /**
        @description
          员工姓名 */
      name?: string;
      /**
        @description
          员工手机号 */
      mobile?: string;
      /**
        @description
          员工邮箱 */
      email?: string;
      /**
        @description
          员工角色 */
      role_id?: string;
    };
  };
}

/** @description response type for postApiV1ApiEmployeeCreate */
export interface PostApiV1ApiEmployeeCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeCreateResponseSuccess =
  PostApiV1ApiEmployeeCreateResponse[200];
/**
 * @description
 *   创建员工
 *   创建员工
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/create";
  function request(
    option?: PostApiV1ApiEmployeeCreateOption
  ): Promise<PostApiV1ApiEmployeeCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserPasswordChange */
export interface PostApiV1UserPasswordChangeOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
    /**
        @description
        @example
           */
    "x-secret-code"?: string;
  };
  body?: UserChangePasswordRequest;
}

/** @description response type for postApiV1UserPasswordChange */
export interface PostApiV1UserPasswordChangeResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1UserPasswordChangeResponseSuccess =
  PostApiV1UserPasswordChangeResponse[200];
/**
 * @description
 *   修改当前账号登录密码
 * @tags 用户端-用户管理
 * @x-apifox-folder "用户端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserPasswordChange = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/password/change";
  function request(
    option?: PostApiV1UserPasswordChangeOption
  ): Promise<PostApiV1UserPasswordChangeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserPasswordChangeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiSummaryModify */
export interface PostApiV1ApiSummaryModifyOption {
  body?: {
    /**
        @description
          总结ID */
    summary_id: string;
    data?: {
      status?: string;
    };
  };
}

/** @description response type for postApiV1ApiSummaryModify */
export interface PostApiV1ApiSummaryModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiSummaryModifyResponseSuccess =
  PostApiV1ApiSummaryModifyResponse[200];
/**
 * @description
 *   编辑总结
 *   编辑总结
 * @tags 客户端接口.总结
 * @x-apifox-folder "客户端接口.总结"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiSummaryModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/summary/modify";
  function request(
    option?: PostApiV1ApiSummaryModifyOption
  ): Promise<PostApiV1ApiSummaryModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiSummaryModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRoleList */
export interface PostApiV1AgentAgentRoleListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRoleListRequest;
}

/** @description response type for postApiV1AgentAgentRoleList */
export interface PostApiV1AgentAgentRoleListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentRoleListResponse;
  };
}

export type PostApiV1AgentAgentRoleListResponseSuccess =
  PostApiV1AgentAgentRoleListResponse[200];
/**
 * @description
 *   获取代理角色列表
 * @tags 代理端-代理角色管理
 * @x-apifox-folder "代理端-代理角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRoleList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/role/list";
  function request(
    option?: PostApiV1AgentAgentRoleListOption
  ): Promise<PostApiV1AgentAgentRoleListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRoleListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramDetail */
export interface PostApiV1ApiProgramDetailOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人信息 */
      with_director?: number;
      /**
        @description
          关联条件.查询统计信息 */
      with_stats?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramDetail */
export interface PostApiV1ApiProgramDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramDetailResponseSuccess =
  PostApiV1ApiProgramDetailResponse[200];
/**
 * @description
 *   获取项目详情
 *   获取项目详情
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/detail";
  function request(
    option?: PostApiV1ApiProgramDetailOption
  ): Promise<PostApiV1ApiProgramDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageDetail */
export interface PostApiV1ApiProgramStageDetailOption {
  body?: {
    /**
        @description
          阶段ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
    condition?: {
      /**
        @description
          关联条件.周期 */
      with_cycles?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramStageDetail */
export interface PostApiV1ApiProgramStageDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageDetailResponseSuccess =
  PostApiV1ApiProgramStageDetailResponse[200];
/**
 * @description
 *   获取阶段详情
 *   获取阶段详情
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/detail";
  function request(
    option?: PostApiV1ApiProgramStageDetailOption
  ): Promise<PostApiV1ApiProgramStageDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountRemove */
export interface PostApiV1AdminAdminAccountRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountRemoveRequest;
}

/** @description response type for postApiV1AdminAdminAccountRemove */
export interface PostApiV1AdminAdminAccountRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAdminAccountRemoveResponseSuccess =
  PostApiV1AdminAdminAccountRemoveResponse[200];
/**
 * @description
 *   删除管理账号
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/remove";
  function request(
    option?: PostApiV1AdminAdminAccountRemoveOption
  ): Promise<PostApiV1AdminAdminAccountRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRoleCreate */
export interface PostApiV1AgentAgentRoleCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRoleCreateRequest;
}

/** @description response type for postApiV1AgentAgentRoleCreate */
export interface PostApiV1AgentAgentRoleCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentRoleCreateResponseSuccess =
  PostApiV1AgentAgentRoleCreateResponse[200];
/**
 * @description
 *   创建代理角色
 * @tags 代理端-代理角色管理
 * @x-apifox-folder "代理端-代理角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRoleCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/role/create";
  function request(
    option?: PostApiV1AgentAgentRoleCreateOption
  ): Promise<PostApiV1AgentAgentRoleCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRoleCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultModify */
export interface PostApiV1ApiTaskResultModifyOption {
  body?: {
    /**
        @description
          任务成果ID */
    result_id: string;
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          阶段ID */
      stage_id?: string;
      /**
        @description
          周期ID */
      cycle_id?: string;
      /**
        @description
          成果内容 */
      result?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskResultModify */
export interface PostApiV1ApiTaskResultModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultModifyResponseSuccess =
  PostApiV1ApiTaskResultModifyResponse[200];
/**
 * @description
 *   编辑任务成果
 *   编辑任务成果
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/modify";
  function request(
    option?: PostApiV1ApiTaskResultModifyOption
  ): Promise<PostApiV1ApiTaskResultModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1InnerOrderChargeCallback */
export interface PostApiV1InnerOrderChargeCallbackOption {
  body?: ChargeCallbackRequest;
}

/** @description response type for postApiV1InnerOrderChargeCallback */
export interface PostApiV1InnerOrderChargeCallbackResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ResponseInterface;
  };
}

export type PostApiV1InnerOrderChargeCallbackResponseSuccess =
  PostApiV1InnerOrderChargeCallbackResponse[200];
/**
 * @description
 *   充值回调
 * @tags 内部接口-订单
 * @x-apifox-folder "内部接口-订单"
 * @x-apifox-status "released"
 */
export const postApiV1InnerOrderChargeCallback = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/inner/order/charge/callback";
  function request(
    option?: PostApiV1InnerOrderChargeCallbackOption
  ): Promise<PostApiV1InnerOrderChargeCallbackResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1InnerOrderChargeCallbackResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreCreate */
export interface PostApiV1AdminStoreCreateOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreCreateRequest;
}

/** @description response type for postApiV1AdminStoreCreate */
export interface PostApiV1AdminStoreCreateResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminStoreCreateResponseSuccess =
  PostApiV1AdminStoreCreateResponse[200];
/**
 * @description
 *   创建站点
 * @tags 总管理后台-站点管理
 * @x-apifox-folder "总管理后台-站点管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/create";
  function request(
    option?: PostApiV1AdminStoreCreateOption
  ): Promise<PostApiV1AdminStoreCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserStatsSnapshotDetail */
export interface PostApiV1UserUserStatsSnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1UserUserStatsSnapshotDetail */
export interface PostApiV1UserUserStatsSnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsSnapshotDetailResponse;
  };
}

export type PostApiV1UserUserStatsSnapshotDetailResponseSuccess =
  PostApiV1UserUserStatsSnapshotDetailResponse[200];
/**
 * @description
 *   获取统计详情
 * @tags 用户端-统计管理
 * @x-apifox-folder "用户端-统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserStatsSnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/user/stats/snapshot/detail";
  function request(
    option?: PostApiV1UserUserStatsSnapshotDetailOption
  ): Promise<PostApiV1UserUserStatsSnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserUserStatsSnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleCreate */
export interface PostApiV1ApiProgramCycleCreateOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          阶段ID */
      stage_id?: string;
      /**
        @description
          周期名称 */
      name?: string;
      /**
        @description
          周期开始时间 */
      start_at?: number;
      /**
        @description
          周期结束时间 */
      end_at?: number;
    };
  };
}

/** @description response type for postApiV1ApiProgramCycleCreate */
export interface PostApiV1ApiProgramCycleCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleCreateResponseSuccess =
  PostApiV1ApiProgramCycleCreateResponse[200];
/**
 * @description
 *   创建周期
 *   创建周期
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/create";
  function request(
    option?: PostApiV1ApiProgramCycleCreateOption
  ): Promise<PostApiV1ApiProgramCycleCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTrashSummaryList */
export interface PostApiV1ApiTrashSummaryListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.任务 */
      with_task?: number;
    };
    search?: {
      /**
        @description
          搜索条件.项目ID */
      program_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTrashSummaryList */
export interface PostApiV1ApiTrashSummaryListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTrashSummaryListResponseSuccess =
  PostApiV1ApiTrashSummaryListResponse[200];
/**
 * @description
 *   获取报告列表
 *   获取报告列表
 * @tags 客户端接口.回收站
 * @x-apifox-folder "客户端接口.回收站"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTrashSummaryList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/trash/summary/list";
  function request(
    option?: PostApiV1ApiTrashSummaryListOption
  ): Promise<PostApiV1ApiTrashSummaryListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTrashSummaryListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreRemove */
export interface PostApiV1AdminStoreRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreRemoveRequest;
}

/** @description response type for postApiV1AdminStoreRemove */
export interface PostApiV1AdminStoreRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminStoreRemoveResponseSuccess =
  PostApiV1AdminStoreRemoveResponse[200];
/**
 * @description
 *   删除站点
 * @tags 总管理后台-站点管理
 * @x-apifox-folder "总管理后台-站点管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/remove";
  function request(
    option?: PostApiV1AdminStoreRemoveOption
  ): Promise<PostApiV1AdminStoreRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminDefaultBonusSettingModify */
export interface PostApiV1AdminDefaultBonusSettingModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: DefaultBonusSettingModifyRequest;
}

/** @description response type for postApiV1AdminDefaultBonusSettingModify */
export interface PostApiV1AdminDefaultBonusSettingModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminDefaultBonusSettingModifyResponseSuccess =
  PostApiV1AdminDefaultBonusSettingModifyResponse[200];
/**
 * @description
 *   更新默认佣金配置
 * @tags 总管理后台-默认佣金配置管理
 * @x-apifox-folder "总管理后台-默认佣金配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminDefaultBonusSettingModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/default/bonus/setting/modify";
  function request(
    option?: PostApiV1AdminDefaultBonusSettingModifyOption
  ): Promise<PostApiV1AdminDefaultBonusSettingModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminDefaultBonusSettingModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeList */
export interface PostApiV1ApiEmployeeListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询角色信息 */
      with_role?: number;
    };
    search?: {
      /**
        @description
          搜索条件.角色ID */
      role_id?: string;
      /**
        @description
          搜索条件.员工状态 */
      status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiEmployeeList */
export interface PostApiV1ApiEmployeeListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeListResponseSuccess =
  PostApiV1ApiEmployeeListResponse[200];
/**
 * @description
 *   获取员工列表
 *   获取员工列表
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/list";
  function request(
    option?: PostApiV1ApiEmployeeListOption
  ): Promise<PostApiV1ApiEmployeeListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminProfilePasswordChange */
export interface PostApiV1AdminProfilePasswordChangeOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: ProfileChangePasswordRequest;
}

/** @description response type for postApiV1AdminProfilePasswordChange */
export interface PostApiV1AdminProfilePasswordChangeResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminProfilePasswordChangeResponseSuccess =
  PostApiV1AdminProfilePasswordChangeResponse[200];
/**
 * @description
 *   修改当前账号登录密码
 * @tags 总管理后台-Profile
 * @x-apifox-folder "总管理后台-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AdminProfilePasswordChange = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/profile/password/change";
  function request(
    option?: PostApiV1AdminProfilePasswordChangeOption
  ): Promise<PostApiV1AdminProfilePasswordChangeResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminProfilePasswordChangeResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentSettingList */
export interface PostApiV1AgentAgentSettingListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentSettingListRequest;
}

/** @description response type for postApiV1AgentAgentSettingList */
export interface PostApiV1AgentAgentSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentSettingListResponse;
  };
}

export type PostApiV1AgentAgentSettingListResponseSuccess =
  PostApiV1AgentAgentSettingListResponse[200];
/**
 * @description
 *   获取代理配置列表
 * @tags 代理端-代理配置管理
 * @x-apifox-folder "代理端-代理配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/setting/list";
  function request(
    option?: PostApiV1AgentAgentSettingListOption
  ): Promise<PostApiV1AgentAgentSettingListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramCycleHandle */
export interface PostApiV1ApiProgramCycleHandleOption {
  body?: {
    /**
        @description
          周期ID */
    cycle_id: string;
    /**
        @description
          阶段ID */
    stage_id: string;
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramCycleHandle */
export interface PostApiV1ApiProgramCycleHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramCycleHandleResponseSuccess =
  PostApiV1ApiProgramCycleHandleResponse[200];
/**
 * @description
 *   操作周期
 *   操作周期
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramCycleHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/cycle/handle";
  function request(
    option?: PostApiV1ApiProgramCycleHandleOption
  ): Promise<PostApiV1ApiProgramCycleHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramCycleHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminAccountDetail */
export interface PostApiV1AdminAdminAccountDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminAccountDetailRequest;
}

/** @description response type for postApiV1AdminAdminAccountDetail */
export interface PostApiV1AdminAdminAccountDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminAccountDetailResponse;
  };
}

export type PostApiV1AdminAdminAccountDetailResponseSuccess =
  PostApiV1AdminAdminAccountDetailResponse[200];
/**
 * @description
 *   获取管理账号详情
 * @tags 总管理后台-管理账号管理
 * @x-apifox-folder "总管理后台-管理账号管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminAccountDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/account/detail";
  function request(
    option?: PostApiV1AdminAdminAccountDetailOption
  ): Promise<PostApiV1AdminAdminAccountDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminAccountDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminProfileDetail */
export interface PostApiV1AdminProfileDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
}

/** @description response type for postApiV1AdminProfileDetail */
export interface PostApiV1AdminProfileDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminAccountDetailResponse;
  };
}

export type PostApiV1AdminProfileDetailResponseSuccess =
  PostApiV1AdminProfileDetailResponse[200];
/**
 * @description
 *   获取当前登录账号详情
 * @tags 总管理后台-Profile
 * @x-apifox-folder "总管理后台-Profile"
 * @x-apifox-status "released"
 */
export const postApiV1AdminProfileDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/profile/detail";
  function request(
    option?: PostApiV1AdminProfileDetailOption
  ): Promise<PostApiV1AdminProfileDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminProfileDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminSystemSettingModify */
export interface PostApiV1AdminSystemSettingModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: SystemSettingModifyRequest;
}

/** @description response type for postApiV1AdminSystemSettingModify */
export interface PostApiV1AdminSystemSettingModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminSystemSettingModifyResponseSuccess =
  PostApiV1AdminSystemSettingModifyResponse[200];
/**
 * @description
 *   更新系统配置
 * @tags 总管理后台-系统配置管理
 * @x-apifox-folder "总管理后台-系统配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminSystemSettingModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/system/setting/modify";
  function request(
    option?: PostApiV1AdminSystemSettingModifyOption
  ): Promise<PostApiV1AdminSystemSettingModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminSystemSettingModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentReset */
export interface PostApiV1AdminAgentResetOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentResetRequest;
}

/** @description response type for postApiV1AdminAgentReset */
export interface PostApiV1AdminAgentResetResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminAgentResetResponseSuccess =
  PostApiV1AdminAgentResetResponse[200];
/**
 * @description
 *   重置代理账号
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentReset = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/reset";
  function request(
    option?: PostApiV1AdminAgentResetOption
  ): Promise<PostApiV1AdminAgentResetResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentResetResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreSettingModify */
export interface PostApiV1AdminStoreSettingModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreSettingModifyRequest;
}

/** @description response type for postApiV1AdminStoreSettingModify */
export interface PostApiV1AdminStoreSettingModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminStoreSettingModifyResponseSuccess =
  PostApiV1AdminStoreSettingModifyResponse[200];
/**
 * @description
 *   更新站点配置
 * @tags 总管理后台-站点配置管理
 * @x-apifox-folder "总管理后台-站点配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreSettingModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/setting/modify";
  function request(
    option?: PostApiV1AdminStoreSettingModifyOption
  ): Promise<PostApiV1AdminStoreSettingModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreSettingModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminDefaultBonusSettingList */
export interface PostApiV1AdminDefaultBonusSettingListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: DefaultBonusSettingListRequest;
}

/** @description response type for postApiV1AdminDefaultBonusSettingList */
export interface PostApiV1AdminDefaultBonusSettingListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: DefaultBonusSettingListResponse;
  };
}

export type PostApiV1AdminDefaultBonusSettingListResponseSuccess =
  PostApiV1AdminDefaultBonusSettingListResponse[200];
/**
 * @description
 *   获取默认佣金配置列表
 * @tags 总管理后台-默认佣金配置管理
 * @x-apifox-folder "总管理后台-默认佣金配置管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminDefaultBonusSettingList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/default/bonus/setting/list";
  function request(
    option?: PostApiV1AdminDefaultBonusSettingListOption
  ): Promise<PostApiV1AdminDefaultBonusSettingListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminDefaultBonusSettingListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentList */
export interface PostApiV1AgentAgentListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentListRequest;
}

/** @description response type for postApiV1AgentAgentList */
export interface PostApiV1AgentAgentListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentListResponse;
  };
}

export type PostApiV1AgentAgentListResponseSuccess =
  PostApiV1AgentAgentListResponse[200];
/**
 * @description
 *   获取代理列表
 * @tags 代理端-子代理管理
 * @x-apifox-folder "代理端-子代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/list";
  function request(
    option?: PostApiV1AgentAgentListOption
  ): Promise<PostApiV1AgentAgentListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramEmployeeRemove */
export interface PostApiV1ApiProgramEmployeeRemoveOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          成员ID
        @default
        @example
           */
    employees: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramEmployeeRemove */
export interface PostApiV1ApiProgramEmployeeRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramEmployeeRemoveResponseSuccess =
  PostApiV1ApiProgramEmployeeRemoveResponse[200];
/**
 * @description
 *   编辑项目成员管理
 *   移除项目成员管理
 * @tags 客户端接口.项目成员管理
 * @x-apifox-folder "客户端接口.项目成员管理"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramEmployeeRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/employee/remove";
  function request(
    option?: PostApiV1ApiProgramEmployeeRemoveOption
  ): Promise<PostApiV1ApiProgramEmployeeRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramEmployeeRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminOrderBonusList */
export interface PostApiV1AdminOrderBonusListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderBonusDetailListRequest;
}

/** @description response type for postApiV1AdminOrderBonusList */
export interface PostApiV1AdminOrderBonusListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderBonusDetailListResponse;
  };
}

export type PostApiV1AdminOrderBonusListResponseSuccess =
  PostApiV1AdminOrderBonusListResponse[200];
/**
 * @description
 *   获取订单分佣明细列表
 * @tags 总管理后台-订单分佣明细管理
 * @x-apifox-folder "总管理后台-订单分佣明细管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminOrderBonusList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/order/bonus/list";
  function request(
    option?: PostApiV1AdminOrderBonusListOption
  ): Promise<PostApiV1AdminOrderBonusListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminOrderBonusListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminOrderList */
export interface PostApiV1AdminOrderListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderListRequest;
}

/** @description response type for postApiV1AdminOrderList */
export interface PostApiV1AdminOrderListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderListResponse;
  };
}

export type PostApiV1AdminOrderListResponseSuccess =
  PostApiV1AdminOrderListResponse[200];
/**
 * @description
 *   获取订单列表
 * @tags 总管理后台-订单管理
 * @x-apifox-folder "总管理后台-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminOrderList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/order/list";
  function request(
    option?: PostApiV1AdminOrderListOption
  ): Promise<PostApiV1AdminOrderListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminOrderListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryModify */
export interface PostApiV1ApiTaskSummaryModifyOption {
  body?: {
    /**
        @description
          任务总结ID */
    summary_id: string;
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          阶段ID */
      stage_id?: string;
      /**
        @description
          周期ID */
      cycle_id?: string;
      /**
        @description
          总结类型 */
      type?: number;
      /**
        @description
          总结内容 */
      result?: string;
    };
  };
}

/** @description response type for postApiV1ApiTaskSummaryModify */
export interface PostApiV1ApiTaskSummaryModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryModifyResponseSuccess =
  PostApiV1ApiTaskSummaryModifyResponse[200];
/**
 * @description
 *   编辑任务总结报告
 *   编辑任务总结报告
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/modify";
  function request(
    option?: PostApiV1ApiTaskSummaryModifyOption
  ): Promise<PostApiV1ApiTaskSummaryModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiEmployeeDetail */
export interface PostApiV1ApiEmployeeDetailOption {
  body?: {
    /**
        @description
          员工ID */
    employee_id: string;
    condition?: {
      /**
        @description
          关联条件.查询角色信息 */
      with_role?: string;
    };
  };
}

/** @description response type for postApiV1ApiEmployeeDetail */
export interface PostApiV1ApiEmployeeDetailResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiEmployeeDetailResponseSuccess =
  PostApiV1ApiEmployeeDetailResponse[200];
/**
 * @description
 *   获取员工详情
 *   获取员工详情
 * @tags 客户端接口.员工
 * @x-apifox-folder "客户端接口.员工"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiEmployeeDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/employee/detail";
  function request(
    option?: PostApiV1ApiEmployeeDetailOption
  ): Promise<PostApiV1ApiEmployeeDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiEmployeeDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAgentDetail */
export interface PostApiV1AdminAgentDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentDetailRequest;
}

/** @description response type for postApiV1AdminAgentDetail */
export interface PostApiV1AdminAgentDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentDetailResponse;
  };
}

export type PostApiV1AdminAgentDetailResponseSuccess =
  PostApiV1AdminAgentDetailResponse[200];
/**
 * @description
 *   获取代理详情
 * @tags 总管理后台-代理管理
 * @x-apifox-folder "总管理后台-代理管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAgentDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/agent/detail";
  function request(
    option?: PostApiV1AdminAgentDetailOption
  ): Promise<PostApiV1AdminAgentDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAgentDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserTodayStats */
export interface PostApiV1AdminUserTodayStatsOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AdminUserTodayStats */
export interface PostApiV1AdminUserTodayStatsResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsSnapshotDetailResponse;
  };
}

export type PostApiV1AdminUserTodayStatsResponseSuccess =
  PostApiV1AdminUserTodayStatsResponse[200];
/**
 * @description
 *   获取用户今日统计
 * @tags 总管理后台-用户管理
 * @x-apifox-folder "总管理后台-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserTodayStats = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/today/stats";
  function request(
    option?: PostApiV1AdminUserTodayStatsOption
  ): Promise<PostApiV1AdminUserTodayStatsResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserTodayStatsResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminAdminRoleDetail */
export interface PostApiV1AdminAdminRoleDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AdminRoleDetailRequest;
}

/** @description response type for postApiV1AdminAdminRoleDetail */
export interface PostApiV1AdminAdminRoleDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AdminRoleDetailResponse;
  };
}

export type PostApiV1AdminAdminRoleDetailResponseSuccess =
  PostApiV1AdminAdminRoleDetailResponse[200];
/**
 * @description
 *   获取角色详情
 * @tags 总管理后台-角色管理
 * @x-apifox-folder "总管理后台-角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminAdminRoleDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/admin/role/detail";
  function request(
    option?: PostApiV1AdminAdminRoleDetailOption
  ): Promise<PostApiV1AdminAdminRoleDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminAdminRoleDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminUserReset */
export interface PostApiV1AdminUserResetOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserResetRequest;
}

/** @description response type for postApiV1AdminUserReset */
export interface PostApiV1AdminUserResetResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminUserResetResponseSuccess =
  PostApiV1AdminUserResetResponse[200];
/**
 * @description
 *   重置用户
 * @tags 总管理后台-用户管理
 * @x-apifox-folder "总管理后台-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminUserReset = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/user/reset";
  function request(
    option?: PostApiV1AdminUserResetOption
  ): Promise<PostApiV1AdminUserResetResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminUserResetResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreModify */
export interface PostApiV1AdminStoreModifyOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreModifyRequest;
}

/** @description response type for postApiV1AdminStoreModify */
export interface PostApiV1AdminStoreModifyResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminStoreModifyResponseSuccess =
  PostApiV1AdminStoreModifyResponse[200];
/**
 * @description
 *   更新站点
 * @tags 总管理后台-站点管理
 * @x-apifox-folder "总管理后台-站点管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/modify";
  function request(
    option?: PostApiV1AdminStoreModifyOption
  ): Promise<PostApiV1AdminStoreModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskModify */
export interface PostApiV1ApiTaskModifyOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          任务ID */
    task_id: string;
    data?: {
      /**
        @description
          标题 */
      task_title?: string;
      /**
        @description
          上级ID */
      parent_task_id?: string;
      /**
        @description
          负责人ID */
      director_id?: string;
      /**
        @description
          任务描述 */
      description?: string;
      /**
        @description
          进度类型 */
      progress_type?: number;
      /**
        @description
          基础值 */
      progress_base?: number;
      /**
        @description
          完成值 */
      progress?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskModify */
export interface PostApiV1ApiTaskModifyResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskModifyResponseSuccess =
  PostApiV1ApiTaskModifyResponse[200];
/**
 * @description
 *   编辑任务
 *   编辑任务
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskModify = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/modify";
  function request(
    option?: PostApiV1ApiTaskModifyOption
  ): Promise<PostApiV1ApiTaskModifyResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskModifyResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramExport */
export interface PostApiV1ApiProgramExportOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    /**
        @description
          阶段ID
        @default
        @example
           */
    stage_ids?: Array<string>;
    /**
        @description
          任务ID
        @default
        @example
           */
    task_ids?: Array<string>;
  };
}

/** @description response type for postApiV1ApiProgramExport */
export interface PostApiV1ApiProgramExportResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramExportResponseSuccess =
  PostApiV1ApiProgramExportResponse[200];
/**
 * @description
 *   导出表格
 *   导出表格
 * @tags 客户端接口.项目
 * @x-apifox-folder "客户端接口.项目"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramExport = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/export";
  function request(
    option?: PostApiV1ApiProgramExportOption
  ): Promise<PostApiV1ApiProgramExportResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramExportResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentRoleRemove */
export interface PostApiV1AgentAgentRoleRemoveOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentRoleRemoveRequest;
}

/** @description response type for postApiV1AgentAgentRoleRemove */
export interface PostApiV1AgentAgentRoleRemoveResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AgentAgentRoleRemoveResponseSuccess =
  PostApiV1AgentAgentRoleRemoveResponse[200];
/**
 * @description
 *   删除代理角色
 * @tags 代理端-代理角色管理
 * @x-apifox-folder "代理端-代理角色管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentRoleRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/role/remove";
  function request(
    option?: PostApiV1AgentAgentRoleRemoveOption
  ): Promise<PostApiV1AgentAgentRoleRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentRoleRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiCycleTaskList */
export interface PostApiV1ApiCycleTaskListOption {
  body?: {
    condition?: {
      /**
        @description
          关联条件.查询任务信息 */
      with_task?: number;
      /**
        @description
          关联条件.查询周期信息 */
      with_cycle?: number;
      /**
        @description
          关联条件.查询任务负责人信息 */
      with_director?: number;
    };
    search?: {
      /**
        @description
          搜索条件.阶段ID */
      stage_id?: string;
      /**
        @description
          搜索条件.周期ID */
      cycle_id?: string;
      /**
        @description
          搜索条件.任务类型 */
      task_type?: number;
      /**
        @description
          搜索条件.任务ID */
      parent_task_id?: string;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiCycleTaskList */
export interface PostApiV1ApiCycleTaskListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiCycleTaskListResponseSuccess =
  PostApiV1ApiCycleTaskListResponse[200];
/**
 * @description
 *   获取周期任务列表
 *   获取周期任务列表
 * @tags 客户端接口.周期
 * @x-apifox-folder "客户端接口.周期"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiCycleTaskList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/cycle/task/list";
  function request(
    option?: PostApiV1ApiCycleTaskListOption
  ): Promise<PostApiV1ApiCycleTaskListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiCycleTaskListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskList */
export interface PostApiV1ApiTaskListOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    condition?: {
      /**
        @description
          关联条件.查询负责人 */
      with_director?: number;
      /**
        @description
          关联条件.查询模块信息 */
      with_module?: number;
    };
    search?: {
      /**
        @description
          搜索条件.任务类型 */
      type?: number;
      /**
        @description
          搜索条件.子级模块 */
      is_sub_module?: number;
      /**
        @description
          搜索条件.模块ID */
      parent_task_id?: string;
      /**
        @description
          搜索条件.任务状态 */
      status?: number;
    };
    sort?: {
      /**
        @description
          排序条件.创建时间排序 */
      create_at?: string;
      /**
        @description
          排序条件.排序 */
      rank_index?: string;
    };
    page?: {
      /**
        @description
          分页参数.页码 */
      page?: number;
      /**
        @description
          分页参数.每页个数 */
      page_size?: number;
      /**
        @description
          分页参数.是否返回总数 */
      total?: number;
      /**
        @description
          分页参数.是否返回全部结果 */
      all?: number;
    };
  };
}

/** @description response type for postApiV1ApiTaskList */
export interface PostApiV1ApiTaskListResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskListResponseSuccess =
  PostApiV1ApiTaskListResponse[200];
/**
 * @description
 *   获取任务列表
 *   获取任务列表
 * @tags 客户端接口.任务
 * @x-apifox-folder "客户端接口.任务"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/list";
  function request(
    option?: PostApiV1ApiTaskListOption
  ): Promise<PostApiV1ApiTaskListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentUserStatsSnapshotDetail */
export interface PostApiV1AgentUserStatsSnapshotDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserStatsSnapshotDetailRequest;
}

/** @description response type for postApiV1AgentUserStatsSnapshotDetail */
export interface PostApiV1AgentUserStatsSnapshotDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserStatsSnapshotDetailResponse;
  };
}

export type PostApiV1AgentUserStatsSnapshotDetailResponseSuccess =
  PostApiV1AgentUserStatsSnapshotDetailResponse[200];
/**
 * @description
 *   获取统计详情
 * @tags 代理端-用户统计管理
 * @x-apifox-folder "代理端-用户统计管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentUserStatsSnapshotDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/userStatsSnapshot/detail";
  function request(
    option?: PostApiV1AgentUserStatsSnapshotDetailOption
  ): Promise<PostApiV1AgentUserStatsSnapshotDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentUserStatsSnapshotDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminStoreDetail */
export interface PostApiV1AdminStoreDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: StoreDetailRequest;
}

/** @description response type for postApiV1AdminStoreDetail */
export interface PostApiV1AdminStoreDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: StoreDetailResponse;
  };
}

export type PostApiV1AdminStoreDetailResponseSuccess =
  PostApiV1AdminStoreDetailResponse[200];
/**
 * @description
 *   获取站点详情
 * @tags 总管理后台-站点管理
 * @x-apifox-folder "总管理后台-站点管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminStoreDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/store/detail";
  function request(
    option?: PostApiV1AdminStoreDetailOption
  ): Promise<PostApiV1AdminStoreDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminStoreDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserDetail */
export interface PostApiV1UserDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserDetailRequest;
}

/** @description response type for postApiV1UserDetail */
export interface PostApiV1UserDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserDetailResponse;
  };
}

export type PostApiV1UserDetailResponseSuccess =
  PostApiV1UserDetailResponse[200];
/**
 * @description
 *   获取用户详情
 * @tags 用户端-用户管理
 * @x-apifox-folder "用户端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/detail";
  function request(
    option?: PostApiV1UserDetailOption
  ): Promise<PostApiV1UserDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentGameList */
export interface PostApiV1AgentGameListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: GameListRequest;
}

/** @description response type for postApiV1AgentGameList */
export interface PostApiV1AgentGameListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: GameListResponse;
  };
}

export type PostApiV1AgentGameListResponseSuccess =
  PostApiV1AgentGameListResponse[200];
/**
 * @description
 *   获取游戏列表
 * @tags 代理端-游戏管理
 * @x-apifox-folder "代理端-游戏管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentGameList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/game/list";
  function request(
    option?: PostApiV1AgentGameListOption
  ): Promise<PostApiV1AgentGameListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentGameListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description response type for postApiV1AgentAgentPermissionList */
export interface PostApiV1AgentAgentPermissionListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentPermissionListResponse;
  };
}

export type PostApiV1AgentAgentPermissionListResponseSuccess =
  PostApiV1AgentAgentPermissionListResponse[200];
/**
 * @description
 *   获取代理权限列表
 * @tags 代理端-代理权限管理
 * @x-apifox-folder "代理端-代理权限管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentPermissionList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/permission/list";
  function request(): Promise<PostApiV1AgentAgentPermissionListResponseSuccess> {
    return requester(url, {
      method,
    }) as unknown as Promise<PostApiV1AgentAgentPermissionListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AdminOrderOpen */
export interface PostApiV1AdminOrderOpenOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderOpenRequest;
}

/** @description response type for postApiV1AdminOrderOpen */
export interface PostApiV1AdminOrderOpenResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: BaseSuccessResponse;
  };
}

export type PostApiV1AdminOrderOpenResponseSuccess =
  PostApiV1AdminOrderOpenResponse[200];
/**
 * @description
 *   人工发奖
 * @tags 总管理后台-订单管理
 * @x-apifox-folder "总管理后台-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AdminOrderOpen = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/admin/order/open";
  function request(
    option?: PostApiV1AdminOrderOpenOption
  ): Promise<PostApiV1AdminOrderOpenResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AdminOrderOpenResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiProgramStageCreate */
export interface PostApiV1ApiProgramStageCreateOption {
  body?: {
    /**
        @description
          项目ID */
    program_id: string;
    data?: {
      /**
        @description
          阶段名称 */
      stage_name?: string;
      /**
        @description
          进度类型 */
      progress_type?: number;
      cycles?: Array<{
        /**
        @description
          周期开始时间 */
        start_at?: number;
        /**
        @description
          周期结束时间 */
        end_at?: number;
      }>;
    };
  };
}

/** @description response type for postApiV1ApiProgramStageCreate */
export interface PostApiV1ApiProgramStageCreateResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiProgramStageCreateResponseSuccess =
  PostApiV1ApiProgramStageCreateResponse[200];
/**
 * @description
 *   创建阶段
 *   创建阶段
 * @tags 客户端接口.阶段
 * @x-apifox-folder "客户端接口.阶段"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiProgramStageCreate = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/program/stage/create";
  function request(
    option?: PostApiV1ApiProgramStageCreateOption
  ): Promise<PostApiV1ApiProgramStageCreateResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiProgramStageCreateResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserLogin */
export interface PostApiV1UserLoginOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserLoginRequest;
}

/** @description response type for postApiV1UserLogin */
export interface PostApiV1UserLoginResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserLoginResponse;
  };
}

export type PostApiV1UserLoginResponseSuccess = PostApiV1UserLoginResponse[200];
/**
 * @description
 *   登录
 * @tags 用户端-用户管理
 * @x-apifox-folder "用户端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserLogin = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/login";
  function request(
    option?: PostApiV1UserLoginOption
  ): Promise<PostApiV1UserLoginResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserLoginResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserUserRelationDetail */
export interface PostApiV1UserUserRelationDetailOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserRelationDetailRequest;
}

/** @description response type for postApiV1UserUserRelationDetail */
export interface PostApiV1UserUserRelationDetailResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: UserRelationDetailResponse;
  };
}

export type PostApiV1UserUserRelationDetailResponseSuccess =
  PostApiV1UserUserRelationDetailResponse[200];
/**
 * @description
 *   获取用户关系详情
 * @tags 用户端-用户关系管理
 * @x-apifox-folder "用户端-用户关系管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserUserRelationDetail = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/user/relation/detail";
  function request(
    option?: PostApiV1UserUserRelationDetailOption
  ): Promise<PostApiV1UserUserRelationDetailResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserUserRelationDetailResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserGoogleAuthBind */
export interface PostApiV1UserGoogleAuthBindOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: UserBindGoogleAuthRequest;
}

/** @description response type for postApiV1UserGoogleAuthBind */
export interface PostApiV1UserGoogleAuthBindResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: ResponseInterface;
  };
}

export type PostApiV1UserGoogleAuthBindResponseSuccess =
  PostApiV1UserGoogleAuthBindResponse[200];
/**
 * @description
 *   绑定Google验证码
 * @tags 用户端-用户管理
 * @x-apifox-folder "用户端-用户管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserGoogleAuthBind = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/google/auth/bind";
  function request(
    option?: PostApiV1UserGoogleAuthBindOption
  ): Promise<PostApiV1UserGoogleAuthBindResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserGoogleAuthBindResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentOrderList */
export interface PostApiV1AgentOrderListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderListRequest;
}

/** @description response type for postApiV1AgentOrderList */
export interface PostApiV1AgentOrderListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderListResponse;
  };
}

export type PostApiV1AgentOrderListResponseSuccess =
  PostApiV1AgentOrderListResponse[200];
/**
 * @description
 *   获取订单列表
 * @tags 代理端-订单管理
 * @x-apifox-folder "代理端-订单管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentOrderList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/order/list";
  function request(
    option?: PostApiV1AgentOrderListOption
  ): Promise<PostApiV1AgentOrderListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentOrderListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1AgentAgentBonusRecordList */
export interface PostApiV1AgentAgentBonusRecordListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: AgentBonusRecordListRequest;
}

/** @description response type for postApiV1AgentAgentBonusRecordList */
export interface PostApiV1AgentAgentBonusRecordListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: AgentBonusRecordListResponse;
  };
}

export type PostApiV1AgentAgentBonusRecordListResponseSuccess =
  PostApiV1AgentAgentBonusRecordListResponse[200];
/**
 * @description
 *   获取代理分红记录列表
 * @tags 代理端-代理分红记录管理
 * @x-apifox-folder "代理端-代理分红记录管理"
 * @x-apifox-status "released"
 */
export const postApiV1AgentAgentBonusRecordList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/agent/agent/bonus/record/list";
  function request(
    option?: PostApiV1AgentAgentBonusRecordListOption
  ): Promise<PostApiV1AgentAgentBonusRecordListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1AgentAgentBonusRecordListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1UserOrderBonusDetailList */
export interface PostApiV1UserOrderBonusDetailListOption {
  /**
   * @description
   * @example
   *
   */
  header?: {
    /**
        @description
        @example
           */
    Authorization?: string;
  };
  body?: OrderBonusDetailListRequest;
}

/** @description response type for postApiV1UserOrderBonusDetailList */
export interface PostApiV1UserOrderBonusDetailListResponse {
  /**
   * @description
   *   OK
   */
  200: {
    /**
        @description
          错误码
        @example
          0 */
    code?: number;
    /**
        @description
          错误信息
        @example
          0 */
    msg?: string;
    data?: OrderBonusDetailListResponse;
  };
}

export type PostApiV1UserOrderBonusDetailListResponseSuccess =
  PostApiV1UserOrderBonusDetailListResponse[200];
/**
 * @description
 *   获取订单分佣明细列表
 * @tags 用户端-订单分佣明细管理
 * @x-apifox-folder "用户端-订单分佣明细管理"
 * @x-apifox-status "released"
 */
export const postApiV1UserOrderBonusDetailList = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/user/order/bonus/detail/list";
  function request(
    option?: PostApiV1UserOrderBonusDetailListOption
  ): Promise<PostApiV1UserOrderBonusDetailListResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1UserOrderBonusDetailListResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskResultRemove */
export interface PostApiV1ApiTaskResultRemoveOption {
  body?: {
    /**
        @description
          任务成果ID */
    result_id: string;
    /**
        @description
          任务ID */
    task_id: string;
  };
}

/** @description response type for postApiV1ApiTaskResultRemove */
export interface PostApiV1ApiTaskResultRemoveResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskResultRemoveResponseSuccess =
  PostApiV1ApiTaskResultRemoveResponse[200];
/**
 * @description
 *   删除任务成果
 *   删除任务成果
 * @tags 客户端接口.任务成果
 * @x-apifox-folder "客户端接口.任务成果"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskResultRemove = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/result/remove";
  function request(
    option?: PostApiV1ApiTaskResultRemoveOption
  ): Promise<PostApiV1ApiTaskResultRemoveResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskResultRemoveResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();

/** @description request parameter type for postApiV1ApiTaskSummaryHandle */
export interface PostApiV1ApiTaskSummaryHandleOption {
  body?: {
    /**
        @description
          任务ID */
    task_id?: string;
    /**
        @description
          任务总结ID */
    summary_id: string;
    /**
        @description
          操作 */
    action: number;
    /**
        @description
          操作参数
        @default
        @example
           */
    params?: Array<string>;
  };
}

/** @description response type for postApiV1ApiTaskSummaryHandle */
export interface PostApiV1ApiTaskSummaryHandleResponse {
  /**
   * @description
   *   default
   */
  200: {};
}

export type PostApiV1ApiTaskSummaryHandleResponseSuccess =
  PostApiV1ApiTaskSummaryHandleResponse[200];
/**
 * @description
 *   操作任务总结报告
 *   操作任务总结报告
 * @tags 客户端接口.任务总结报告
 * @x-apifox-folder "客户端接口.任务总结报告"
 * @x-apifox-status "developing"
 */
export const postApiV1ApiTaskSummaryHandle = /* #__PURE__ */ (() => {
  const method = "post";
  const url = "/api/v1/api/task/summary/handle";
  function request(
    option?: PostApiV1ApiTaskSummaryHandleOption
  ): Promise<PostApiV1ApiTaskSummaryHandleResponseSuccess> {
    return requester(url, {
      method,
      ...option,
    }) as unknown as Promise<PostApiV1ApiTaskSummaryHandleResponseSuccess>;
  }

  /** http method */
  request.method = method;
  /** request url */
  request.url = url;
  return request;
})();
