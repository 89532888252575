import React, { Suspense, lazy, memo, ReactNode, useState, useEffect } from 'react';
import { useNavigate, useLocation, useRoutes, Route, RouteObject, Routes } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
import { Layout, Menu } from 'antd';
import {
  ShareAltOutlined,
  FileSearchOutlined,
  RiseOutlined,
  AreaChartOutlined,
  ControlOutlined,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';

// import Logo from '@/assets/images/logo.png';
// import LogoMin from '@/assets/images/logo-min.png';

import routersAll, { AppRouteObject } from '@/routes';

import Header from './layout-header';

// import { currentRouters } from '@/store';
// import { useCreateRoutes } from '@/utils/utils';

// import LogoTitle from '@/assets/images/logo-title.png'
// import Logo from '@/assets/images/logo.png'
import './index.less';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

// const icons = {
//   'ShareAltOutlined': <ShareAltOutlined />,
//   'FileSearchOutlined': <FileSearchOutlined />,
//   'RiseOutlined': <RiseOutlined />,
//   'AreaChartOutlined': <AreaChartOutlined />,
//   'ControlOutlined': <ControlOutlined />,
//   'FileTextOutlined': <FileTextOutlined />,
//   'UserOutlined': <UserOutlined />,
// }

export default memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  // const routers = useRecoilValue(currentRouters);
  const routers = routersAll;

  const [collapsed, setCollapsed] = useState(false); // 菜单开关
  const [currentPath, setCurrentPath] = useState(''); // 当前路由
  // const [currentPath, setCurrentPath] = useState('') //当前路由

  useEffect(() => {
    if (location.pathname === '/') navigate('/user');
  }, []);

  /**
   * 菜单显示隐藏
   */
  const toggle = () => setCollapsed(!collapsed);

  /**
   * 页面跳转
   * @param {String} path
   */
  const goto = (path: string) => {
    // console.log(path);
    // debugger;
    navigate(path);
    setCurrentPath(path);
  };

  // console.log(location);

  return (
    <Layout className='layout-wrap'>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className='logo'>
          {/* <img src={Logo} className={`h-`} style={{ height: collapsed ? '60%' : '100%' }} /> */}
          {/* logo */}
          {/* {
            collapsed
              ? <img src={Logo} style={{ height: '60%' }}></img>
              : <img src={Logo} style={{ height: '100%' }}></img>
          } */}
        </div>
        {createMenu(routers, goto, currentPath || location.pathname)}
      </Sider>
      <Layout className='site-layout'>
        <Header pathname={currentPath} toggle={toggle} />
        <Content
          className='layout-conent site-layout-background'
          style={{ boxSizing: 'border-box', overflowY: 'auto' }}
        >
          {useRoutes(useCreateRoutes(routers))}
        </Content>
      </Layout>
    </Layout>
  );
});

/**
 * 创建菜单
 * @param {Array} routes 路由配置表
 * @param {Function} onClick 页面跳转
 * @param {Object} location
 * @param {String} currentPath 当前路由
 */
const createMenu = (routes: AppRouteObject[], onClick: (path: string) => void, currentPath: string) => {
  // let selectPath = location.pathname;
  // if (selectPath.includes('program-manage/detail') || selectPath.includes('program-manage/activity-apply'))
  //   selectPath = '/program-manage/list';

  const parent = routersAll.find((item) => item.children?.some((cItem) => cItem.path === currentPath));
  // 菜单默认选中为当前路由

  return (
    <Menu
      theme='dark'
      mode='inline'
      multiple
      defaultOpenKeys={parent?.path ? [parent.path!] : undefined}
      // defaultOpenKeys={routes.map((item) => item.children?.map((citem) => citem.path)).flat(1) as any}
      selectedKeys={[currentPath]}
    >
      {createMenuAction(routes, onClick)}
    </Menu>
  );
};

const createMenuAction = (menus: AppRouteObject[], onClick: (path: string) => void) => {
  return menus.map((menu) => {
    if (menu.children) {
      // console.log(menu.meta?.hidden);

      return !menu.meta?.hidden ? (
        <SubMenu key={menu.path + 'submenu'} title={menu.meta!.title} icon={null}>
          {createMenuAction(menu.children, onClick)}
        </SubMenu>
      ) : null;
    }
    // console.log(menu.icon)
    return (
      !menu.meta?.hidden &&
      createMenuItem({
        path: menu.path!,
        icon: menu.meta?.icon,
        onClick: () => onClick(menu.path!),
        title: menu.meta?.title!,
      })
    );
  });
};

const createMenuItem = ({
  path,
  icon,
  onClick,
  title,
}: {
  path: string;
  icon: ReactNode;
  onClick: () => void;
  title: string;
}) => (
  <Menu.Item key={path} onClick={onClick}>
    {title}
  </Menu.Item>
);

//

/**
 * 生成路由
 * @param {Array} routes 路由配置表
 */
export const createRoutes = (routes: AppRouteObject[], result: AppRouteObject[] = []): AppRouteObject[] => {
  routes.forEach((item) => {
    const loading = <div>Loading...</div>;

    const { meta, element, ...other } = item;
    const currentRouter = item.element ? { element, ...other } : undefined;
    // const ComponentName = lazy(() => import(`@/${item.path}`));
    // const render: ReactNode = (
    //   <Suspense fallback={loading}>
    //     <ComponentName />
    //   </Suspense>
    // );

    // const Component: React.ReactNode = <Route path={item.path!} element={render} key={item.path} />;

    if (item.children && item.children.length) {
      result = [...result, currentRouter ? { element, ...other } : null, ...createRoutes(item.children, result)].filter(
        Boolean,
      ) as AppRouteObject[];
    }

    if (item.element) {
      result.push({ element, ...other });
    }
  });
  // console.log(result);

  return result;
};

/**
 * 创建路由
 * @param {Array} routes
 */
export const useCreateRoutes = (routes: AppRouteObject[]) => createRoutes(routes);
