import { memo, useEffect } from 'react';

import { Button } from 'antd';
import FormRender, { useForm } from 'form-render';
import { useNavigate } from 'react-router-dom';
// import { profileActions } from 'src/store/actions';

// import LoginFooter from '@/assets/images/login-footer.png';
// import Logo from '@/assets/images/logo.png';
// import LoginBg from '@/assets/images/login-bg.png';
import { useAppDispatch } from '@/hooks';
import { Api } from '@/services/apis';
import { postWebSysUserLogin } from '@/services/api-tsg';
import { sha1 } from '@/utils';
import { TOKEN } from '@/constants';
// import { postApiV1ApiIndexLogin } from 'src/services/zm';
// import { TOKEN } from 'src/constant';

// import { sha1 } from 'src/utils';

import './index.less';

const schema = {
  type: 'object',
  properties: {
    phone: {
      title: '账号',
      type: 'string',
      required: true,
    },
    password: {
      title: '密码',
      type: 'string',
      required: true,
      props: {
        type: 'password',
      },
    },
  },
};

export default memo(function Login() {
  const form = useForm();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // const Authorization = localStorage.getItem(TOKEN);
    // if (Authorization) navigate('/home', { replace: true });
  }, []);

  const onFinish = async (formData: any, errors: any) => {
    // debugger;
    if (errors.length) return;

    const { accessToken } = await postWebSysUserLogin({ body: formData });

    localStorage.setItem(TOKEN, accessToken!);
    // await dispatch(profileActions.getUserinfoAction());
    navigator('/user');
  };

  return (
    <div className='login-wrap h-full flex justify-center items-center -mt-40'>
      {/* <img className='login-bg' src={LoginBg} alt='' /> */}
      <section className='flex flex-col items-center rounded-lg bg-white pt-8 relative'>
        <div>{/* <img src={Logo} alt='' /> */}</div>
        {/* <h3>小熊运动管理后台</h3> */}
        <div className='my-5 text-xl font-bold'>你好，欢迎登陆</div>

        <div className='login-form-wrap'>
          <FormRender form={form} schema={schema} onFinish={onFinish} />
          <div>
            <Button className='w-full h-10' type='primary' onClick={form.submit} loading={false}>
              登录
            </Button>
          </div>
        </div>
      </section>
      {/* <img className='login-footer-img' src={LoginFooter} alt='' /> */}
    </div>
  );
});
