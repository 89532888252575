import React from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import moment from 'moment';
import zhCN from 'antd/lib/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/dist/locale/zh-cn';
import store from '@/store';

import App from './App';
import Login from './pages/login';
import 'antd/dist/antd.min.css';
import './assets/css/index.less';

// console.log(locale.lang);

moment.locale('zh-cn');
// const root = createRoot(document.getElementById('root')!);
ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/*' element={<App />} />
          </Routes>
        </Provider>
      </ConfigProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')!,
);
